import { PrimaryButton } from '@shared/components'
import React from 'react'
import { WidgetProps } from '../PromptContentWidget'

export const PaymentMethodInsuranceAccepted = ({ onSubmit }: WidgetProps) => {
  return (
    <div>
      PaymentMethodInsuranceAccepted HOORAY!
      <PrimaryButton
        onClick={() => {
          onSubmit({
            value: {
              contextKey: 'insurance_accepted_next',
            },
          })
        }}
      >
        Next
      </PrimaryButton>
    </div>
  )
}
