import { PrimaryButton, Stack, Text, TitleTwo } from '@shared/components'
import React from 'react'

export const StartTasksSection = ({
  isMobile,
  handleStartTasks,
}: {
  isMobile: boolean
  handleStartTasks: () => void
}) => {
  return (
    <Stack>
      <TitleTwo>Get started on your intake tasks</TitleTwo>
      <Text>
        This is similar to the paperwork you&apos;d fill out the first time you visit an in-person
        doctor. And it&apos;s a great way for us to get acquainted with you. (If you get stuck or
        don&apos;t have time to finish, that&apos;s okay!)
      </Text>
      <PrimaryButton fullWidth={isMobile} onClick={handleStartTasks}>
        Start tasks
      </PrimaryButton>
    </Stack>
  )
}
