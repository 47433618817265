import { z } from 'zod'
import { ApiSchemas } from '../../..'

const PostInsuranceCoverageReqResSchemas = {
  req: z.object({
    body: z.object({
      payerId: z.string(),
      memberId: z.string(),
      firstName: z.string(),
      lastName: z.string(),
      birthday: z.string(),
      gender: z.string(),
    }),
  }),
  res: z.object({
    data: z.object({
      isActive: z.boolean(),
    }),
  }),
}

const PostPayersByNameReqResSchemas = {
  req: z.object({
    body: z.object({
      q: z.string(),
      limit: z.number().optional(),
    }),
  }),
  res: z.object({
    data: z.array(
      z.object({
        confidence: z.number(),
        eligiblePayerId: z.string(),
        eligiblePayerNameCased: z.string(),
      }),
    ),
  }),
}

export const MyInsuranceCoverageApi = {
  'POST /insurance-coverage': PostInsuranceCoverageReqResSchemas,
  'POST /payers': PostPayersByNameReqResSchemas,
} satisfies ApiSchemas
