import { z } from 'zod'
import { ApiSchemas } from '../../..'

const GetReferralOrganizationsReqRes = {
  req: z.object({}),
  res: z.object({
    data: z.array(
      z.object({
        name: z.string(),
        createdAt: z.string(),
        totalReferrals: z.number(),
      }),
    ),
  }),
}

const CreateReferralOrganizationReqRes = {
  req: z.object({
    body: z.object({
      name: z.string(),
    }),
  }),
  res: z.object({
    data: z.null(),
  }),
}

export const ReferralOrganizationsRouteSchemas = {
  'GET /referralOrganizations': GetReferralOrganizationsReqRes,
  'POST /referralOrganizations': CreateReferralOrganizationReqRes,
} satisfies ApiSchemas
