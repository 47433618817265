import { isEmail, useForm } from '@mantine/form'
import { Stack, skipIfOtherField, validateWith } from '@shared/components'
import { IntendedPaymentMethod } from '@shared/types'
import { CITY_STATE_OTHER, CITY_STATE_UNKNOWN } from '@shared/utils'
import React, { useState } from 'react'
import { isActiveUsStateName, isBirthday, isPhone, isRequired } from '../../common/forms'
import { ReferralFormPage } from './referralForm/ReferralFormPage'
import { ReferralIntakeScheduledPage } from './referralIntakeScheduled/ReferralIntakeScheduledPage'
import { ReferralReviewPage } from './referralReview/ReferralReviewPage'

export type ReferralsStep = 'form' | 'review' | 'intake-scheduled'

const orderedReferralSteps: ReferralsStep[] = ['form', 'review', 'intake-scheduled']

export type ReferralFormForm = {
  referralState: string
  intendedPaymentMethod: IntendedPaymentMethod | ''
  insuranceProvider: string
  referralFirstName: string
  referralLastName: string
  referralSex: string
  referralDob: string
  referralPhone: string
  referralEmail: string
  referrerType: string
  referrerName: string
  referrerOrganization: string
  referrerPhone: string
  referrerEmail: string
  referrerCity: string
  referrerState: string
  intakeDatetime: string
  skipIntakeVisitSchedule: boolean
}

export const Referrals = () => {
  const [currentStep, setCurrentStep] = useState<ReferralsStep>('form')

  const referralForm = useForm<ReferralFormForm>({
    initialValues: {
      intendedPaymentMethod: '',
      insuranceProvider: '',
      referralState: '',
      referralFirstName: '',
      referralLastName: '',
      referralSex: '',
      referralDob: '',
      referralPhone: '',
      referralEmail: '',
      referrerType: '',
      referrerName: '',
      referrerOrganization: '',
      referrerPhone: '',
      referrerEmail: '',
      referrerCity: '',
      referrerState: '',
      intakeDatetime: '',
      skipIntakeVisitSchedule: false,
    },
    validate: {
      intendedPaymentMethod: validateWith(isRequired),
      insuranceProvider: validateWith(
        skipIfOtherField('intendedPaymentMethod', 'not', 'Insurance'),
        isRequired,
      ),
      referralState: validateWith(isRequired, isActiveUsStateName),
      referralFirstName: validateWith(isRequired),
      referralLastName: validateWith(isRequired),
      referralSex: validateWith(isRequired),
      referralDob: validateWith(isRequired, isBirthday),
      referralPhone: validateWith(isRequired, isPhone),
      referralEmail: validateWith(isRequired, isEmail),
      referrerType: validateWith(isRequired),
      referrerName: validateWith(
        skipIfOtherField('referrerType', 'is', 'friend_family_colleague'),
        isRequired,
      ),
      referrerOrganization: validateWith(
        skipIfOtherField('referrerType', 'is', 'friend_family_colleague'),
        isRequired,
      ),
      referrerPhone: validateWith(
        skipIfOtherField('referrerType', 'is', 'friend_family_colleague'),
        isRequired,
        isPhone,
      ),
      referrerEmail: validateWith(
        skipIfOtherField('referrerType', 'is', 'friend_family_colleague'),
        isRequired,
        isEmail,
      ),
      referrerCity: validateWith(
        skipIfOtherField('referrerType', 'is', 'friend_family_colleague'),
        skipIfOtherField('referrerState', 'is', CITY_STATE_OTHER),
        skipIfOtherField('referrerState', 'is', CITY_STATE_UNKNOWN),
        isRequired,
      ),
      referrerState: validateWith(
        skipIfOtherField('referrerType', 'is', 'friend_family_colleague'),
        isRequired,
      ),
      intakeDatetime: validateWith(
        skipIfOtherField('skipIntakeVisitSchedule', 'is', true),
        isRequired,
      ),
    },
  })

  const onSubmitReferralForm = () => {
    if (referralForm.validate().hasErrors) {
      return
    }

    goToNextStep()
  }

  const startNewReferral = () => {
    setCurrentStep('form')
    referralForm.reset()
  }

  const goToNextStep = () => {
    const currentStepIndex = orderedReferralSteps.indexOf(currentStep)
    const nextStep = orderedReferralSteps[currentStepIndex + 1]

    if (typeof nextStep !== 'undefined') {
      setCurrentStep(nextStep)
    }
  }

  const goToPreviousStep = () => {
    const currentStepIndex = orderedReferralSteps.indexOf(currentStep)
    const prevStep = orderedReferralSteps[currentStepIndex - 1]

    if (typeof prevStep !== 'undefined') {
      setCurrentStep(prevStep)
    }
  }

  return (
    <Stack test-id='page:referrals'>
      {currentStep === 'form' && (
        <ReferralFormPage form={referralForm} onSubmit={onSubmitReferralForm} />
      )}
      {currentStep === 'review' && (
        <ReferralReviewPage form={referralForm} onBack={goToPreviousStep} onSubmit={goToNextStep} />
      )}
      {currentStep === 'intake-scheduled' && (
        <ReferralIntakeScheduledPage form={referralForm} onSubmit={startNewReferral} />
      )}
    </Stack>
  )
}

export default Referrals
