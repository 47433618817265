import { IANAZone, dayjs } from '@shared/utils'
import { useCallMeNowQueueStatus } from './use-call-me-now-queue-status'
import { useFlags } from './use-flags'
import { usePortalDims } from './use-portal-dims'

export const useWelcomeChatAvailability = () => {
  const { isQueueOpen } = useCallMeNowQueueStatus()
  const { welcomeChatExperiment } = useFlags()

  // Only available on weekdays due to limited EC capacity on weekends
  const isTodayAWeekday =
    dayjs().tz(IANAZone.Eastern).day() >= 1 && dayjs().tz(IANAZone.Eastern).day() <= 5

  // Only available between 10am est and 5pm est
  const isWithinBusinessHours = () => {
    const now = dayjs().tz(IANAZone.Eastern)
    const start = now.hour(10).minute(0)
    const end = now.hour(17).minute(0)
    return now.isAfter(start) && now.isBefore(end)
  }

  const { isMobile } = usePortalDims()

  return {
    isWelcomeChatAvailable:
      Boolean(welcomeChatExperiment && isTodayAWeekday && isQueueOpen) &&
      isMobile &&
      isWithinBusinessHours(),
  }
}
