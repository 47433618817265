import { BetterModal, Box, Center, ModalContent, Stack, Text, TitleOne } from '@shared/components'
import { sleep } from '@shared/utils'
import Lottie from 'lottie-react'
import React, { useEffect } from 'react'

type AnimationModalProps = {
  opened: boolean
  onClose: () => void
  animation: Record<string, unknown>
  title: string
  tagline: string
  timeout?: Parameters<typeof sleep>[0]
}

const AnimationModal = ({
  opened,
  onClose,
  animation,
  title,
  tagline,
  timeout,
}: AnimationModalProps) => {
  useEffect(() => {
    const handleTimeout = async () => {
      if (opened && timeout) {
        await sleep(timeout)
        onClose()
      }
    }
    void handleTimeout()
  }, [onClose, opened, timeout])

  return (
    <BetterModal onClose={onClose} opened={opened} size='unset'>
      <ModalContent>
        <Center sx={({ spacing }) => ({ margin: -spacing.md })}>
          <Box sx={{ maxWidth: '8.5rem', maxHeight: '8.5rem' }}>
            <Lottie animationData={animation} />
          </Box>
        </Center>
        <Stack p='md' spacing='sm' align='center' maw='70vw'>
          <TitleOne>{title}</TitleOne>
          <Text align='center'>{tagline}</Text>
        </Stack>
      </ModalContent>
    </BetterModal>
  )
}

export default AnimationModal
