import { z } from 'zod'
import { ISOStringSchema } from '../../../dates'
import { ApiSchemas } from '../../utils'

const GetIntakeExpiration = {
  req: z.object({}),
  res: z.object({
    data: z.object({
      intakeTaskExpirationDatetime: ISOStringSchema.nullable(),
    }),
  }),
}

export const MyIntakeTasksApi = {
  'GET /intake-tasks/expiration': GetIntakeExpiration,
} satisfies ApiSchemas
