import type { AppointmentTypeString } from '@shared/types'

export const isCall = (type: AppointmentTypeString): boolean => {
  switch (type) {
    case 'Check-In Call':
    case 'Induction Check-In Call':
    case 'Free Consultation Call':
    case 'Returning welcome call':
    case 'Enrollment Support Call':
    case 'Support Call':
      return true
    case 'Follow-Up Visit':
    case 'Initial Visit':
    case 'Peer Coaching Follow Up Visit':
    case 'Peer Coaching Initial Visit':
    case 'UDS Visit':
    case 'Wellness Visit':
    case 'Initial Wellness Visit':
      return false
  }
}

// eg: 30-minute visit or 30-minute call
export const getFormattedDuration = (duration: string, type: AppointmentTypeString) =>
  `${duration}-minute ${isCall(type) ? 'call' : 'visit'}`
