const PATIENT_RELEASE_OF_INFORMATION_STATUS_CHANGE_EVENTS = [
  'Release of Information Approved',
  'Release of Information Under Review',
  'Release of Information Needs Changes',
  'Release of Information Expired',
  'Release of Information Revoked',
  'Release of Information In Progress',
] as const

export type PatientReleaseOfInformationStatusChangeEvent =
  (typeof PATIENT_RELEASE_OF_INFORMATION_STATUS_CHANGE_EVENTS)[number]

export const REFERRAL_SEGMENT_EVENTS = [
  'Provider Referral Created',
  'Friend Referral Link Copied',
  'New Referral Created',
  'Referral Link Clicked',
  'Existing Referral Found',
  'Referral Link Clicked & Existing Referral Found',
  'New Provider Referral Captured During Welcome Call',
] as const

export type ReferralSegmentEvent = (typeof REFERRAL_SEGMENT_EVENTS)[number]
export const SEGMENT_EVENT_NAMES = [
  'Account Created',
  'Account Email Confirmed',
  'Account Recovery Requested',
  'Account Recovery Contact Visible',
  'Add Us To Your Contacts',
  'Added to Insurance Allow List',
  'Addendum added',
  'Appointment Canceled',
  'Appointment Expired',
  'Appointment Confirmation Complete',
  'Appointment Marked Finished',
  'Appointment Reminded',
  'Calendar Hold Reminded',
  'Calendar Hold Created',
  'Appointment Rescheduled',
  'Appointment Scheduled',
  'Appointment Updated',
  'Appointment Transferred',
  'Autologin',
  'Click Check My Insurance On Welcome Page',
  'Care Transfer Response',
  'Care Transfer Cohort Response',
  'Consultation Call Scheduled For Later While CMN Open',
  'Consultation Call Queue Status Requested',
  'Consultation Call Queue Status Updated',
  'Copay Invoice Created',
  'Corrections Flow State Selected',
  'Cost Analysis Page Visited',
  'Cost Analysis Page Progress',
  'Coverage Results Step Visible',
  'Coverage Search Options Step Visible',
  'Duplicate Phone Number Submitted For Account Creation',
  'Duplicate Email Submitted For Account Creation',
  'EMR Email Reassigned',
  'EMR Phone Reassigned',
  'Existing Account Create Request',
  'Financial Agreement Consent Signed',
  'Failure to pay',
  'Treatment Consent Signed',
  'ID Uploaded',
  'Induction Note Locked',
  'Insurance Coverage Page Visited',
  'Insurance Data Updated',
  'Insurance Review Status Updated',
  'Insurance Select Step Visible',
  'Insurance Updated',
  'Insurance Verification Failed',
  'Insurance Verified',
  'Insurance Marked Has',
  'Insurance Marked None',
  'Intake Completed',
  'Lab ordered',
  'Lead Created',
  'Lead Redirected To Insurance Task',
  'Learn More Clicked',
  'Level of Care Status Changed',
  'Milestone Shared',
  'Not Ready Clicked',
  'Note Attested',
  'Note signed',
  'No Patient Facing Appointments Available',
  'No Patient Facing Appointments Available For Hold',
  'Notification Marked As Seen',
  'Nurse Care Manager Updated',
  'Ophelia.com Session',
  'Patient Added To Payors Queue',
  'Patient Availability Updated',
  'Patient Booked Intake Via Expedited Care Transfer Onboarding Flow',
  'Patient Booked Intake Via Corrections Referral Flow',
  'Patient Marked As Candidate On Care Transfer Intake Appointment Scheduled',
  'Patient Opted In To Welcome Call Standby',
  'Patient Portal OTP Requested',
  'Patient Portal Password Updated',
  'Patient Portal Phone Updated',
  'Patient Portal Zoom Embed Initialization Failed',
  'Patient Portal Zoom Embed Initialization Succeeded',
  'Patient Portal Zoom Embed Join Failed',
  'Patient Portal Zoom Embed Join Succeeded',
  'Patient Portal Zoom Embed Fallback Selected',
  'Patient Selected Get Started With Intake Visit',
  'Patient Status Changed',
  'Patient Suicide Risk',
  'Password Reset Request',
  'Password Reset Request (No account found)',
  'Password Updated',
  'Payment Failed',
  'Payment Succeeded',
  'Payment Source Created',
  'Personal Data Created',
  'Personal Data Updated',
  'Prescribing Clinician Updated',
  'Pre-WC Start Tasks Button Clicked',
  'Prompt Signout Modal Visible',
  'Queued Consultation Call Created',
  'Recover Your Account Clicked',
  'Referral Flow State Selected',
  'Returning to Ophelia Clicked',
  'Schedule My Free Intro Call Clicked',
  'Scheduled For Later Survey Submitted',
  'Send Email Again Clicked',
  'Self Service Appointment Scheduled',
  'Self Service Appointment Rescheduled',
  'Shipment updated',
  'Shipment tracking url created',
  'Shipping Data Updated',
  'Signed Out From Workflow Prompt',
  'Skip Onboarding Drawer Open',
  'State Identified From Screening Form',
  'Subscription Updated',
  'Subscription Restarted Because Removed From Allow List',
  'Subscription Not Started Because Patient On Allow List',
  'Standby Notification Clicked',
  'Standby Notification Triggered',
  'Standby Notification Preferences Set',
  'Standby List Task Opened',
  'Standby List Task Seen',
  'Standby List Snapshot',
  'Standby Appointment Rescheduled',
  'Standby Appointment Already Taken',
  'Standby Appointment No Slots Open On Calendar Load',
  'Standby Welcome Call Rescheduled',
  // @deprecated
  'Send Follow-Up Standby Appointment Availability Notification',
  // @deprecated
  'Send Next Appointment Standby Availability Notification',
  'Send Appointment Pull Forward Notification',
  'Send Appointment Push Back Notification',
  'Update Clinician Availability Status',
  'Send Individual MBC Form Reminder',
  'Send MBC Form Reminder',
  'Task Completed',
  'Welcome Call Booked',
  'Welcome Call Topic Survey Submitted',
  'Zoom Redirect Page Error',
  'Zoom Redirect Page Visited',
  'File Uploaded',
  'Pay Now Clicked',
  'Call Pharmacy Clicked',
  'Join Zoom Clicked',
  'New Standby Welcome Call Scheduled After Cancelation',
  'No-Show Invoice Created',
  'No-Show Invoice Failed',
  'Onboarding Skipped To Book Instant Welcome Call',
  'Required Tasks Completed',
  'State Collected In Welcome Flow',
  'Release of Information Created',
  'Release of Information Deleted',
  'Home Data Updated',
  'Completed MBC',
  'Prescription Sent',
  'Waived No Show Used',
  'Patient would like to speak with a financial counselor',
  'ABP Non-Compliance',
  'Invoice Voided',
  'Upcoming Subscription Invoice',
  'Partner Welcome Page - Have A Question Visible',
  'Partner Welcome Page - Have A Question Clicked',
  'ABP Created',
  'Chatbox Status Changed',
  'Ophelink Preview Assets Requested',
  'Update Standby Status',
  'Calendar Fine Tuning',
  'Successfully Rescheduled Standby Appointment',
  'Eligible for Drop-In Clinic',
  'Confirmed for Drop-In Clinic',
  'Send Drop-In Clinic Reminder',
  'Send Drop-In Clinic Survey',
  'Added to Drop-In Clinic',
  'Workflow variant assigned',
  ...PATIENT_RELEASE_OF_INFORMATION_STATUS_CHANGE_EVENTS,
  ...REFERRAL_SEGMENT_EVENTS,
  'Call Out',
  'Ineligible Patient HLOC Transfer',
  'Discharged Patient HLOC Transfer',
  'Call Us Now Pressed',
  'Zero Dollar Alert Visible',
  'Test Zoom Clicked',
  'Visit FAQs Clicked',
  'Send MCO Transfer Information',
  'DoseSpot V2 Migration',
  'State-Specific FFS Billing',
  // @experimentFramework
  'Patient Assigned To Experiment Cohort',
  // @welcomeCallReschedulingExperiment
  '[Welcome Call Experiment] No Appointment Found',
  '[Welcome Call Experiment] Clicked Preemptive Link But Is No Show',
  '[Welcome Call Experiment] Clicked No Show Link But Is Preemptive',
  '[Welcome Call Experiment] Not Able To Reschedule Welcome Call',
  '[Welcome Chat] Chatbox Closed',
  '[Welcome Chat] Chatbox Visible',
  '[Welcome Chat] Cohort Response',
  '[Welcome Chat] Start Tasks Button Clicked',
  '[Welcome Chat] Branching Determined',
  '[Welcome Chat] Signed Out',
  'Welcome Flow Experiment Cohorted',
] as const

export type SegmentEventName = (typeof SEGMENT_EVENT_NAMES)[number]

export type SegmentMethod = 'track' | 'identify'

export type SegmentIntegrations = {
  'Google Analytics'?: {
    clientId: string
  }
}

/**
 * NOTE: This interface is almost an exact copy of the `apiObject` type from Rudderstack.
 * This only modification is that `undefined` is not a permitted value, as that would
 * violate our Firestore rule against writing undefined to the database.
 */
type SegmentRecord = {
  [index: string]:
    | string
    | number
    | boolean
    | SegmentRecord
    | (string | number | boolean | SegmentRecord)[]
}

export type SegmentContextProperties = SegmentRecord

export type SegmentTraits = SegmentRecord

export type SegmentContext = {
  event?: SegmentEventName
  properties?: SegmentContextProperties
  integrations?: SegmentIntegrations
  userId?: string
  anonymousId?: string
  traits?: SegmentTraits
}

// This matches the RS Contextual Fields
export type SegmentAdditionalContext = {
  ip?: string
  userAgent?: string
}
