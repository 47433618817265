import { UseFormReturnType } from '@mantine/form'
import { Grid, PhoneInput, Select, Stack, Text, TextInput, TitleTwo } from '@shared/components'
import { referralSourceOptions, states } from '@shared/types'
import { validCharacters } from '@shared/utils'
import React, { useEffect } from 'react'
import { CityStateSelectGroup } from '../../../common/components/CityStateSelectGroup'
import { ReferralFormForm } from '../Referrals'

type ReferringOrganizationSectionProps = {
  form: UseFormReturnType<ReferralFormForm>
}

export const ReferringOrganizationSection = ({ form }: ReferringOrganizationSectionProps) => {
  const isSaving = false

  const isNonOrganization = form.values.referrerType === 'friend_family_colleague'

  useEffect(() => {
    if (isNonOrganization) {
      form.setValues({
        referrerName: '',
        referrerOrganization: '',
        referrerPhone: '',
        referrerEmail: '',
        referrerCity: '',
        referrerState: '',
      })
      form.validate()
    }
  }, [form.values.referrerType])

  return (
    <Stack>
      <TitleTwo>Lastly, share your information</TitleTwo>
      <Text>{`We'll reach out to you with any questions about this referral.`}</Text>
      <Grid>
        <Grid.Col span={12} md={12}>
          <Select
            searchable
            placeholder='Select one...'
            label='Your organization type'
            data={referralSourceOptions}
            {...form.getInputProps('referrerType')}
          />
        </Grid.Col>
        <Grid.Col span={12} md={6}>
          <TextInput
            label='Your full name'
            placeholder='Your full name'
            maxLength={64}
            disabled={isSaving || isNonOrganization}
            formatter={validCharacters.name}
            {...form.getInputProps('referrerName')}
          />
        </Grid.Col>
        <Grid.Col span={12} md={6}>
          <TextInput
            label='Organization name'
            placeholder='E.g. Example Medical Center'
            maxLength={128}
            disabled={isSaving || isNonOrganization}
            {...form.getInputProps('referrerOrganization')}
          />
        </Grid.Col>
        <Grid.Col span={12} md={6}>
          <PhoneInput
            label='Your work phone number'
            disabled={isSaving || isNonOrganization}
            {...form.getInputProps('referrerPhone')}
          />
        </Grid.Col>
        <Grid.Col span={12} md={6}>
          <TextInput
            label='Your work email address'
            placeholder='Your work email address'
            disabled={isSaving || isNonOrganization}
            {...form.getInputProps('referrerEmail')}
          />
        </Grid.Col>

        <Grid.Col span={12} md={12}>
          <CityStateSelectGroup
            states={states}
            disabled={isSaving || isNonOrganization}
            inputProps={form.getInputProps}
            cityPropName='referrerCity'
            statePropName='referrerState'
            cityLabel='Organization city'
            stateLabel='Organization state'
          />
        </Grid.Col>
      </Grid>
    </Stack>
  )
}
