import { Select, SelectProps } from '@shared/components'
import { Sex } from '@shared/types'
import capitalize from 'lodash/capitalize'
import React, { useMemo } from 'react'

export type SexSelectProps = Omit<SelectProps, 'data'>

export const SexSelect = ({
  label = 'Sex assigned at birth',
  placeholder = 'Select one...',
  ...props
}: SexSelectProps) => {
  const sexData = useMemo(
    () =>
      (['male', 'female'] as Sex[]).map(value => ({
        value,
        label: capitalize(value),
      })),
    [],
  )

  return <Select data={sexData} label={label} placeholder={placeholder} {...props} />
}
