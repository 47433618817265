import { PrimaryButton } from '@shared/components'
import React from 'react'
import { WidgetProps } from '../PromptContentWidget'

export const PaymentMethodInNetworkInsuranceSelection = ({ onSubmit }: WidgetProps) => {
  const submitInNetworkInsuranceHandler = () => {
    onSubmit({
      value: {
        contextKey: 'we_accept_your_insurance',
        // the OID of the payer
        value: '',
      },
      // as long as we have insurance provider, we can do whatever wa want with it, see `translateCustomValue`
      insurance_provider: {
        contextKey: 'custom',
        // store Qgenda display name of the payer + ID with underscore separator and then parse differently in translate function on backend!
        value: `Qgenda Display Name_${'foo'}`,
      },
    })
  }

  const submitOtherInsuranceHandler = () => {
    onSubmit({
      value: {
        contextKey: 'has_other_insurance',
        value: 'other_none_of_these',
      },
    })
  }

  return (
    <div>
      <PrimaryButton onClick={submitOtherInsuranceHandler}>OTHER INSURANCE</PrimaryButton>
      <PrimaryButton onClick={submitInNetworkInsuranceHandler}>IN NETWORK INSURANCE</PrimaryButton>
    </div>
  )
}
