// eslint-disable-next-line no-restricted-imports
import { Box, Group, Stack, StackProps } from '@mantine/core'
import React, { PropsWithChildren, ReactNode } from 'react'
import { CircleWithText } from './CircleWithText'
import { ScrollArea } from './ScrollArea'
import { CornerDownRightIcon } from './icons'
import { TitleTwo } from './typography'

type TaskBarProps = {
  header: string
  subheader?: ReactNode
  footer: ReactNode
  number: number
} & StackProps

export const TaskBar = ({
  header,
  subheader,
  footer,
  children,
  sx,
  number,
  ...props
}: PropsWithChildren<TaskBarProps>) => {
  return (
    <Stack
      sx={[
        ({ other: { colors } }) => ({ backgroundColor: colors.background[1] }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...props}
    >
      <Group pt='lg' pl='lg' pr='lg' align='flex-start'>
        <CornerDownRightIcon color={colors => colors.error[0]} />
        <TitleTwo>{header}</TitleTwo>
        <CircleWithText sx={({ other: { colors } }) => ({ backgroundColor: colors.error[0] })}>
          {number}
        </CircleWithText>
      </Group>
      {subheader && (
        <Box pl='lg' pr='lg'>
          {subheader}
        </Box>
      )}
      <ScrollArea type='never' test-id='scroll-area:task-bar'>
        <Group pl='lg' pr='lg' noWrap>
          {children}
        </Group>
      </ScrollArea>
      <Box pb='lg' pl='lg' pr='lg'>
        {footer}
      </Box>
    </Stack>
  )
}
