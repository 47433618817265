import { useClipboard } from '@mantine/hooks'
import {
  Box,
  Card,
  CopyIcon,
  Drawer,
  Flex,
  Image,
  MessageCircleIcon,
  PrimaryButton,
  showNotification,
  Stack,
  Text,
  TitleFour,
  TitleOne,
  TitleTwo,
  useMantineTheme,
} from '@shared/components'
import React, { useEffect } from 'react'

const FloatingCPMDrawerButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <PrimaryButton
      component='a'
      target='_blank'
      style={{
        position: 'fixed',
        bottom: 50,
        right: 25,
        borderRadius: '50%',
      }}
      leftIcon={<MessageCircleIcon />}
      onClick={onClick}
    />
  )
}

type ColorsType = {
  background: string[]
  actions: string[]
}

const CPMCard = ({
  imageSrc,
  location,
  name,
  email,
  colors,
}: {
  imageSrc: string
  location: string
  name: string
  email: string
  colors: ColorsType
}) => {
  const { copy } = useClipboard()

  return (
    <Card styles={{ backgroundColor: colors.actions[0] }}>
      <Flex gap='md' align='center'>
        <Image style={{ mixBlendMode: 'hard-light', width: '6.25rem' }} src={imageSrc} />
        <Box h='auto'>
          <TitleFour color={colors.background[0] ?? 'black'}>{location}</TitleFour>
          <TitleTwo color={colors.background[0] ?? 'black'}>{name}</TitleTwo>
          <Flex gap='xs' align='center'>
            <Text size='md' bold color={colors.background[0] ?? 'black'}>
              {email}
            </Text>
            <CopyIcon
              size='sm'
              color={colors.background[0] ?? 'black'}
              onClick={() => {
                copy(email)
                showNotification({
                  message: `Email successfully copied`,
                  variant: 'success',
                })
              }}
              style={{ cursor: 'pointer' }}
            />
          </Flex>
        </Box>
      </Flex>
    </Card>
  )
}

export const CPMDrawer = () => {
  const [isOpen, setIsOpen] = React.useState(false)
  const onClose = () => {
    setIsOpen(false)
    localStorage.setItem('cpm_drawer_closed', Date.now().toString())
  }
  const {
    other: { colors },
  } = useMantineTheme()

  useEffect(() => {
    const closedTimestamp = localStorage.getItem('cpm_drawer_closed')
    if (closedTimestamp) {
      const oneWeekInMillis = 7 * 24 * 60 * 60 * 1000
      const currentTime = Date.now()
      if (currentTime - parseInt(closedTimestamp, 10) < oneWeekInMillis) {
        return
      }
    }

    const timer = setTimeout(() => {
      setIsOpen(true)
    }, 3000)
    return () => clearTimeout(timer)
  }, [])

  return (
    <>
      <Drawer size='lg' opened={isOpen} onClose={onClose} position='right' title=''>
        <Stack p='lg' spacing='md'>
          <Stack>
            <TitleOne align='center'>Our team is here to help</TitleOne>
            <Text align='center'>
              Reach out to a Community Partnerships Manager in your area to get help with making a
              referral or to learn more about our program.
            </Text>
          </Stack>
          <CPMCard
            imageSrc='https://storage.cloud.google.com/ophelia-production.appspot.com/images/employeePhotos/2eWQKZLpRMXYTnAVgJsdCHBo479N.png?authuser=2'
            location='new york'
            name='Katrina'
            email='katrina.koren@ophelia.com'
            colors={colors}
          />
          <CPMCard
            imageSrc='https://storage.cloud.google.com/ophelia-production.appspot.com/images/employeePhotos/5dLxnVQYPRKMAgWZTfscJHoB3ND8.png?authuser=2'
            location='pennsylvania'
            name='Ashley'
            email='amazei@ophelia.com'
            colors={colors}
          />
          <CPMCard
            imageSrc='https://storage.cloud.google.com/ophelia-production.appspot.com/images/employeePhotos/9bTvyQXnZMRApLsCfghJKWuV1NB5.png?authuser=2'
            location='pennsylvania'
            name='Kaelee'
            email='kaelee.shepherd@ophelia.com'
            colors={colors}
          />
          <CPMCard
            imageSrc='https://storage.cloud.google.com/ophelia-production.appspot.com/images/employeePhotos/7cPmwYKgXQZBrToAdsvLEHpN2MC6.png?authuser=2'
            location='new jersey'
            name='Regina'
            email='regina@ophelia.com'
            colors={colors}
          />
        </Stack>
      </Drawer>
      <FloatingCPMDrawerButton onClick={() => setIsOpen(true)} />
    </>
  )
}
