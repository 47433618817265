import { UseFormReturnType } from '@mantine/form'
import { Group, PrimaryButton, Stack } from '@shared/components'
import React from 'react'
import { CPMDrawer } from '../referralDrawer/CPMDrawer'
import { ReferralFormForm } from '../Referrals'
import { InsuranceSection } from './InsuranceSection'
import { IntakeVisitScheduleSection } from './IntakeVisitScheduleSection'
import { PersonalInformationSection } from './PersonalInformationSection'
import { ReferralFormHeader } from './ReferralFormHeader'
import { ReferringOrganizationSection } from './ReferringOrganizationSection'

type ReferralFormPageProps = {
  onSubmit: () => void
  form: UseFormReturnType<ReferralFormForm>
}

export const ReferralFormPage = ({ onSubmit, form }: ReferralFormPageProps) => {
  return (
    <Stack spacing='lg'>
      <ReferralFormHeader />
      <InsuranceSection form={form} />
      <PersonalInformationSection form={form} />
      <IntakeVisitScheduleSection form={form} />
      <ReferringOrganizationSection form={form} />
      <Group position='right'>
        <PrimaryButton onClick={onSubmit}>Review and submit referral</PrimaryButton>
      </Group>
      <CPMDrawer />
    </Stack>
  )
}
