import { getSessionStorageItem } from '../storage'
import { useAuth } from './use-auth'
import { useFlags } from './use-flags'
import { useMyQuery } from './use-my-query'

export const useCareTransferAvailability = () => {
  const { currentUser } = useAuth()
  const { careTransferExperiment } = useFlags()

  const activeStatesQuery = useMyQuery('GET /intake-states')

  const activeStatesData = activeStatesQuery?.data?.data?.intakeStates

  const residenceState =
    getSessionStorageItem('residence_state') || currentUser?.data?.homeData?.state || ''

  return {
    isCareTransferAvailable: careTransferExperiment && activeStatesData?.includes(residenceState),
  }
}
