import { UseFormReturnType } from '@mantine/form'
import {
  Alert,
  BookmarkIcon,
  BoxIcon,
  CalendarIcon,
  ClockIcon,
  CreditCardIcon,
  Grid,
  Group,
  HomeIcon,
  MailIcon,
  MapPinIcon,
  PhoneIcon,
  PrimaryButton,
  SecondaryButton,
  Stack,
  Text,
  TitleFour,
  TitleTwo,
  UserIcon,
} from '@shared/components'
import { InsurancePlanId, referralSourceOptions } from '@shared/types'
import {
  CITY_STATE_OTHER,
  CITY_STATE_UNKNOWN,
  dayjs,
  getPayerIdAndProvider,
  insuranceOtherOptionValue,
  name,
} from '@shared/utils'
import capitalize from 'lodash/capitalize'
import React from 'react'
import { ReferralFormForm } from '../Referrals'

type ReferralReviewPageProps = {
  onSubmit: () => void
  onBack: () => void
  form: UseFormReturnType<ReferralFormForm>
}

export const ReferralReviewPage = ({ form, onSubmit, onBack }: ReferralReviewPageProps) => {
  const referralFullName = name({
    first: form.values.referralFirstName,
    last: form.values.referralLastName,
  }).full()
  const referralSex = capitalize(form.values.referralSex)

  const insuranceProvider =
    form.values.insuranceProvider === insuranceOtherOptionValue
      ? null
      : getPayerIdAndProvider(form.values.insuranceProvider as InsurancePlanId)?.provider

  const insuranceProviderText =
    form.values.intendedPaymentMethod === 'Insurance' && insuranceProvider ? (
      <Text>{insuranceProvider}</Text>
    ) : (
      <Text color={colors => colors.text[1]}>No insurance selected</Text>
    )

  const intakeDatetime = dayjs(form.values.intakeDatetime)

  const intakeDateText = intakeDatetime.isValid() ? (
    <Text>{intakeDatetime.format('MM/DD/YYYY')}</Text>
  ) : (
    <Text color={colors => colors.text[1]}>Not scheduled</Text>
  )

  const intakeTimeText = intakeDatetime.isValid() ? (
    <Text>{intakeDatetime.format('h:mma z')}</Text>
  ) : (
    <Text color={colors => colors.text[1]}>Not scheduled</Text>
  )

  const getReferrerCityState = () => {
    const referrerCity = [CITY_STATE_OTHER, CITY_STATE_UNKNOWN].includes(form.values.referrerCity)
      ? undefined
      : form.values.referrerCity
    const referrerState = [CITY_STATE_OTHER, CITY_STATE_UNKNOWN].includes(form.values.referrerState)
      ? undefined
      : form.values.referrerState

    if (referrerCity && referrerState) {
      return <Text>{[referrerCity, referrerState].join(', ')}</Text>
    } else if (referrerState) {
      return <Text>{referrerState}</Text>
    }

    return <Text color={colors => colors.text[1]}>No location provided</Text>
  }

  const referrerType = referralSourceOptions.find(
    option => option.value === form.values.referrerType,
  )?.label

  return (
    <Stack spacing='lg'>
      <Alert icon={<BookmarkIcon />} variant='warning'>
        Your referral has not been submitted yet
      </Alert>
      <TitleTwo>Review your referral details</TitleTwo>
      <Stack>
        <TitleFour>Referral info</TitleFour>
        <Grid>
          <Grid.Col span={12} md={6}>
            <Group>
              <UserIcon />
              <Text>{[referralFullName, referralSex].join(', ')}</Text>
            </Group>
          </Grid.Col>
          <Grid.Col span={12} md={6}>
            <Group>
              <CalendarIcon />
              <Text>{form.values.referralDob}</Text>
            </Group>
          </Grid.Col>
          <Grid.Col span={12} md={6}>
            <Group>
              <PhoneIcon />
              <Text>{form.values.referralPhone}</Text>
            </Group>
          </Grid.Col>
          <Grid.Col span={12} md={6}>
            <Group>
              <MailIcon />
              <Text>{form.values.referralEmail}</Text>
            </Group>
          </Grid.Col>
          <Grid.Col span={12} md={6}>
            <Group>
              <MapPinIcon />
              <Text>{form.values.referralState}</Text>
            </Group>
          </Grid.Col>
          <Grid.Col span={12} md={6}>
            <Group>
              <CreditCardIcon />
              {insuranceProviderText}
            </Group>
          </Grid.Col>
        </Grid>
      </Stack>
      <Stack>
        <TitleFour>Intake visit</TitleFour>
        <Grid>
          <Grid.Col span={12} md={6}>
            <Group>
              <CalendarIcon />
              {intakeDateText}
            </Group>
          </Grid.Col>
          <Grid.Col span={12} md={6}>
            <Group>
              <ClockIcon />
              {intakeTimeText}
            </Group>
          </Grid.Col>
        </Grid>
      </Stack>
      {form.values.referrerType !== 'friend_family_colleague' && (
        <Stack>
          <TitleFour>Your info</TitleFour>
          <Grid>
            <Grid.Col span={12} md={6}>
              <Group>
                <UserIcon />
                <Text>{form.values.referrerName}</Text>
              </Group>
            </Grid.Col>
            <Grid.Col span={12} md={6}>
              <Group>
                <MapPinIcon />
                <Text>{getReferrerCityState()}</Text>
              </Group>
            </Grid.Col>
            <Grid.Col span={12} md={6}>
              <Group>
                <BoxIcon />
                <Text>{referrerType}</Text>
              </Group>
            </Grid.Col>
            <Grid.Col span={12} md={6}>
              <Group>
                <HomeIcon />
                <Text>{form.values.referrerOrganization}</Text>
              </Group>
            </Grid.Col>
            <Grid.Col span={12} md={6}>
              <Group>
                <PhoneIcon />
                <Text>{form.values.referrerPhone}</Text>
              </Group>
            </Grid.Col>
            <Grid.Col span={12} md={6}>
              <Group>
                <MailIcon />
                <Text>{form.values.referrerEmail}</Text>
              </Group>
            </Grid.Col>
          </Grid>
        </Stack>
      )}
      <Group position='right'>
        <SecondaryButton onClick={onBack}>Back</SecondaryButton>
        <PrimaryButton onClick={onSubmit}>Submit referral</PrimaryButton>
      </Group>
    </Stack>
  )
}
