import { AppShell, BannerPortal, Container, useBanner } from '@shared/components'
import React, { PropsWithChildren, ReactElement, ReactNode, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useOnboardingDims, useScrollIntoViewOnLayoutEffect } from '../../hooks'
import { OnboardingHeader } from './Header'

type BaseShellProps = {
  header: ReactElement
  footer: ReactElement
  children: ReactNode
}

const BaseShell = ({ header, footer, children }: BaseShellProps) => {
  const location = useLocation()
  const { desktopMediaQuery, mobileMediaQuery } = useOnboardingDims()
  const { targetRef } = useScrollIntoViewOnLayoutEffect({
    dependencyList: [location.pathname],
    duration: 0,
  })
  const { cleanBanner } = useBanner()

  useEffect(() => {
    return cleanBanner
    // Clean up all state when page changes
  }, [cleanBanner, location.pathname])

  return (
    <AppShell
      fixed={false}
      ref={targetRef}
      header={header}
      footer={footer}
      styles={theme => ({
        root: {
          minHeight: '100vh',
          color: theme.other.colors.text[0],
          backgroundColor: theme.other.colors.background[0],
          display: 'flex',
          flexDirection: 'column',
        },
        main: { padding: 0 },
        body: {
          [mobileMediaQuery]: { flexGrow: 1 },
          [desktopMediaQuery]: { flexGrow: 0 },
        },
      })}
    >
      <BannerPortal mb='md' />
      <Container
        sx={theme => ({
          [desktopMediaQuery]: {
            marginTop: `calc(${theme.spacing.md} * 3)`,
            padding: theme.spacing.lg,
            borderWidth: theme.other.sizes.border.md,
            borderStyle: 'solid',
            /* eslint-disable-next-line no-magic-numbers */
            borderColor: theme.other.colors.background[1],
            borderRadius: theme.radius.sm,
            /* eslint-disable-next-line no-magic-numbers */
            maxWidth: `calc(${theme.spacing.md} * 40)`,
          },
          [mobileMediaQuery]: {
            paddingTop: theme.spacing.md,
            paddingLeft: theme.spacing.lg,
            paddingBottom: theme.spacing.md,
            paddingRight: theme.spacing.lg,
          },
        })}
      >
        {children}
      </Container>
    </AppShell>
  )
}

export const OnboardingShell = ({
  children,
  showCallMeNowStatus = false,
}: PropsWithChildren<{ showCallMeNowStatus?: boolean }>) => {
  return (
    <BaseShell
      footer={<div />}
      header={<OnboardingHeader showCallMeNowStatus={showCallMeNowStatus} />}
    >
      {children}
    </BaseShell>
  )
}
