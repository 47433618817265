import { useForm } from '@mantine/form'
import {
  DailyCalendar,
  DailyCalendarRadioGroup,
  PrimaryButton,
  Radio,
  Stack,
  Text,
  isAnySelected,
  useBanner,
} from '@shared/components'
import {
  AppointmentTypeString,
  GetPatientTaskResponse,
  PatientScheduleTaskType,
} from '@shared/types'
import { dayjs, toTime } from '@shared/utils'
import React, { useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { patientApi } from '../../../../common/api'
import { ContactSupport } from '../../../../common/components'
import { CalendarMessage } from '../../../../common/components/CalendarMessage'
import { usePortalDims } from '../../../../common/hooks'
import { TaskHeader } from '../TaskHeader'
import { UsePatientTaskSubmit } from '../use-patient-task'

type ScheduleBookingStepProps = {
  type: AppointmentTypeString
  title: string
  payload: GetPatientTaskResponse<PatientScheduleTaskType>
  submit: UsePatientTaskSubmit<PatientScheduleTaskType>
  isSaving: boolean
}

export const ScheduleBookingStep = ({
  type,
  payload,
  title,
  submit,
  isSaving,
}: ScheduleBookingStepProps) => {
  if (payload.slots.length === 0) {
    return (
      <>
        <TaskHeader title="Nothing's available right now">
          <Text>Check back later to see if new times become available.</Text>
        </TaskHeader>
        <ContactSupport />
      </>
    )
  }

  return (
    <ScheduleCalendar
      type={type}
      payload={payload}
      title={title}
      submit={submit}
      isSaving={isSaving}
    />
  )
}

const ScheduleCalendar = ({ type, payload, title, submit, isSaving }: ScheduleBookingStepProps) => {
  const { showBanner, hideBanner } = useBanner()
  const { isMobile } = usePortalDims()
  const range = useMemo(() => payload.slots.map(slot => slot.date), [payload.slots])
  const [date, setDate] = useState(dayjs(range[0]))

  const {
    data: timeslots,
    isLoading: isTimeSlotsLoading,
    refetch: refetchTimeSlots,
  } = useQuery(
    ...patientApi.getQuery('GET /appointments/calendar/:type/:date', {
      params: { type, date: date.format('YYYY-MM-DD') },
      query: {
        calendarId: String(payload.calendarId),
        visitTypeId: String(payload.visitTypeId),
        startDatetime: payload.startDatetime,
      },
    }),
    {
      cacheTime: toTime('10 sec').ms(),
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      onError: () => showBanner({ type: 'error', label: 'Something went wrong, try again later' }),
    },
  )

  const { getInputProps, validate, values, clearErrors, setValues } = useForm({
    initialValues: { datetime: '' },
    validate: { datetime: isAnySelected(timeslots?.map(slot => slot.time) ?? [], 'Required') },
  })

  return (
    <>
      <TaskHeader title={title} />
      <Stack>
        <DailyCalendar
          value={date.toString()}
          range={range}
          disabled={isSaving}
          onChange={value => {
            clearErrors()
            setValues({ datetime: '' })
            setDate(dayjs(value))
            hideBanner()
          }}
        >
          <DailyCalendarRadioGroup
            size={6}
            loading={isTimeSlotsLoading}
            empty={<CalendarMessage>Sorry, we&apos;re booked on this day.</CalendarMessage>}
            {...getInputProps('datetime')}
          >
            {timeslots?.map(slot => (
              <Radio
                key={slot.time}
                value={slot.time}
                label={dayjs(slot.time).format('h:mma z')}
                disabled={isSaving}
              />
            ))}
          </DailyCalendarRadioGroup>
        </DailyCalendar>
        <PrimaryButton
          test-id='button:schedule'
          fullWidth={isMobile}
          loading={isSaving}
          onClick={async () => {
            if (!validate().hasErrors) {
              try {
                await submit({
                  datetime: values.datetime,
                  calendarID: timeslots?.find(slot => slot.time === values.datetime)
                    ?.calendarIDs[0],
                })
              } catch {
                void refetchTimeSlots()
                showBanner({
                  type: 'error',
                  label: 'Unable to book the chosen slot, try another slot',
                })
              }
            }
          }}
        >
          Schedule visit
        </PrimaryButton>
      </Stack>
    </>
  )
}
