import { UseFormReturnType } from '@mantine/form'
import {
  Alert,
  Group,
  PhoneIcon,
  PrimaryButton,
  PrinterIcon,
  SecondaryButton,
  ShieldIcon,
  Stack,
  Text,
  TitleOne,
  useBanner,
  useLifecycle,
} from '@shared/components'
import { dayjs } from '@shared/utils'
import React from 'react'
import { ReferralFormForm } from '../Referrals'

type ReferralIntakeScheduledPageProps = {
  onSubmit: () => void
  form: UseFormReturnType<ReferralFormForm>
}

export const ReferralIntakeScheduledPage = ({
  onSubmit,
  form,
}: ReferralIntakeScheduledPageProps) => {
  const { showBanner } = useBanner()

  useLifecycle({
    onMount: () => {
      showBanner({
        label: 'We received your referral',
        type: 'success',
        dismissable: false,
      })
    },
  })

  const { referralFirstName, referralLastName, intakeDatetime, skipIntakeVisitSchedule } =
    form.values

  const formattedIntakeDatetime = dayjs(intakeDatetime).format('MM/DD/YYYY [at] h:mm A [EST]')

  return (
    <Stack spacing='lg'>
      {intakeDatetime && !skipIntakeVisitSchedule && (
        <Alert
          icon={<ShieldIcon />}
          title={`${referralFirstName} ${referralLastName}’s intake visit is scheduled for ${formattedIntakeDatetime}`}
          variant='primary'
        >
          We emailed {referralFirstName} a link to sign in to our patient portal and complete some
          simple intake tasks. These tasks need to be completed no later than{' '}
          {formattedIntakeDatetime}, otherwise the intake visit will be canceled. The tasks will
          require access to a smartphone or computer and may involve using a credit or debit card.
        </Alert>
      )}
      <TitleOne>Thank you! Here’s what to expect next:</TitleOne>
      <Stack spacing='xs'>
        <Text className='print-content'>
          1. We’ll reach out to {referralFirstName} to go over our program
        </Text>
        <Text className='print-content'>
          2. {referralFirstName} will complete some simple intake tasks in our patient portal
        </Text>
        <Text className='print-content'>
          3. {referralFirstName} will have their first virtual visit with their clinician
        </Text>
      </Stack>
      <Alert icon={<PhoneIcon />} variant='secondary'>
        Text or call (215) 585–2144 for support
      </Alert>
      <Group position='right'>
        <SecondaryButton leftIcon={<PrinterIcon />} onClick={() => print()}>
          Print
        </SecondaryButton>
        <PrimaryButton onClick={onSubmit}>Make another referral</PrimaryButton>
      </Group>
    </Stack>
  )
}
