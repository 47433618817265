import { isNotEmpty } from '@mantine/form'
import {
  FormValuePredicate,
  isAddressFormat,
  isAmountWords,
  isAnySelected,
  isBirthdayFormat,
  isCreditCardNumberFormat,
  isCvvCodeFormat,
  isDateFormat,
  isDateRelativeRange,
  isDigitCodeLength,
  isEmailFormat,
  isLength,
  isMonthFormat,
  isPhoneFormat,
  isValue,
  isYearFormat,
  skipIfEmpty,
  validateWith,
} from '@shared/components'
import {
  DOSESPOT_ADDRESS_CHARACTER_LIMIT,
  FormFieldValue,
  activeStates,
  stateNames,
} from '@shared/types'

export const isRequired = isNotEmpty('Required')
export const isFullName = isAmountWords({ amount: 2, op: '>=' }, 'First and last name required')
export const isAtleastOneWord = isAmountWords({ amount: 1, op: '>=' }, 'Invalid')
export const isDate = isDateFormat('Use MM/DD/YYYY format')
export const isEmail = isEmailFormat('Invalid email')
export const isPhone = isPhoneFormat('Invalid')
export const isBirthday = isBirthdayFormat('Invalid')
export const isAddress = isAddressFormat('Invalid')
export const isZipCode = isDigitCodeLength(5, 'ZIP code must have 5 numbers')
export const isExpiryMonth = isMonthFormat('Invalid expiration month')
export const isExpiryYear = isYearFormat('Invalid expiration year')
export const isCvvCode = isCvvCodeFormat('Invalid CVV code')
export const isCreditCardNumber = isCreditCardNumberFormat('Invalid credit card number')
export const isTodayOrLater = isDateRelativeRange(
  { min: { value: 0, unit: 'day' } },
  'You should pick a date today or in the future',
)
export const isTomorrowOrLater = isDateRelativeRange(
  { min: { value: 1, unit: 'day' } },
  'You should pick a date in the future',
)
export const isWithin6Months = isDateRelativeRange(
  { min: { value: 0, unit: 'day' }, max: { value: 6, unit: 'months' } },
  'Date should be within 6 months from today',
)
export const isFileNotLoading = isValue<string>(
  { value: 'loading', op: '!=' },
  'Wait for file to finish uploading',
)
export const inputCharacterLimitExceeded = ({ characterLimit }: { characterLimit: number }) =>
  isLength({ length: characterLimit, op: '<=' }, `Must be fewer than ${characterLimit} characters`)

export const formAddressRules: Record<
  keyof FormFieldValue<'address'>,
  FormValuePredicate<FormFieldValue<'address'>[keyof FormFieldValue<'address'>]>
> = {
  address: validateWith(
    isRequired,
    isAddress,
    inputCharacterLimitExceeded({ characterLimit: DOSESPOT_ADDRESS_CHARACTER_LIMIT }),
  ),
  addressTwo: validateWith(skipIfEmpty, isAddress),
  city: validateWith(isRequired, isAddress),
  state: isAnySelected(stateNames, 'Required'),
  zipCode: validateWith(isRequired, isZipCode),
}

export const isInactiveState = isValue<string>(
  { value: 'other', op: '!=' },
  'We don’t see patients in your referral’s state of residence',
)

export const isActiveUsStateName = isAnySelected(
  activeStates.map(state => state.state),
  `Unfortunately, we're not active in this state yet`,
)
