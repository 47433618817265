import { Stack, Text, TitleOne } from '@shared/components'
import React from 'react'

export const ReferralFormHeader = () => {
  return (
    <Stack>
      <TitleOne>Make a referral to Ophelia, leading provider of opioid use disorder care</TitleOne>
      <Text>
        {`Fill out the form below to make a referral to Ophelia — you'll even have the opportunity to
        book their first intake visit. Any information submitted here will be kept secure and
        confidential.`}
      </Text>
    </Stack>
  )
}
