import { PrimaryButton } from '@shared/components'
import React from 'react'
import { WidgetProps } from '../PromptContentWidget'

export const PaymentMethodInsuranceNotAccepted = ({ onSubmit }: WidgetProps) => {
  return (
    <div>
      PaymentMethodInsuranceNotAccepted
      <PrimaryButton
        onClick={() => {
          onSubmit({
            value: {
              contextKey: 'insurance_not_accepted_next',
            },
          })
        }}
      >
        Continue to schedule intro call
      </PrimaryButton>
    </div>
  )
}
