import dayjs from 'dayjs'
import { IANAZone } from './dayjs'

export type CallMeNowQueueStatus = 'open' | 'closed' | 'closing soon' | 'full'

export const getNumWeeksFromNow = (date?: string) => {
  if (!date) {
    return 0
  }

  /*
   * .week() gets the week number of the year, which is helpful because it allows us to determine if a date is
   * in this calendar week or a future/past week. For example, if today is Friday, and the intendedDay is next
   * Monday, the difference in weeks will be 1
   *
   * We also need to subtract 1 day to each date to account for dayjs().week() starting on Sunday
   */
  const thisWeek = dayjs().subtract(1, 'day').startOf('week')
  const appointmentWeek = dayjs(date).subtract(1, 'day').startOf('week')
  return appointmentWeek.diff(thisWeek, 'weeks')
}

export const getTimeDiffString = (date: string) => {
  /*
   * We will only worry about getting the absolute difference. The client can worry about whether
   * it's a past or future date.
   */

  const now = dayjs()
  const differenceInDays = Math.floor(Math.abs(now.diff(date, 'days')))
  const differenceInHours = Math.floor(Math.abs(now.diff(date, 'hours')))
  const differenceInMinutes = Math.floor(Math.abs(now.diff(date, 'minutes')))

  if (differenceInMinutes < 60) {
    return `${differenceInMinutes}m`
  }

  if (differenceInHours < 24) {
    const formattedMinutes = differenceInMinutes % 60

    if (formattedMinutes === 0) {
      return `${differenceInHours}h`
    }

    return `${differenceInHours}h ${formattedMinutes}m`
  }

  if (differenceInDays < 7) {
    const formattedHours = differenceInHours % 24

    if (formattedHours === 0) {
      return `${differenceInDays}d`
    }

    return `${differenceInDays}d ${formattedHours}h`
  }

  const formattedDays = differenceInDays % 7

  if (formattedDays === 0) {
    return `${formattedDays}w`
  }

  return `${formattedDays}w ${formattedDays}d`
}

export const DEFAULT_START_DELAY_HOURS = 3

/**
 *
 * This function accepts the queue status and returns the "next weekday"
 * based on the current day and queue status.
 */
export const getNextWeekdayBasedOnQueueStatus = ({
  queueStatus,
  today,
}: {
  queueStatus: CallMeNowQueueStatus
  today: dayjs.Dayjs
}) => {
  // get day of the week as a string (ex. 'monday')
  const dayOfWeek = today.getDay()

  const isQueueClosed = queueStatus === 'closed'

  let nextWeekday = today

  // Determine the next weekday based on the current day and queue status
  switch (dayOfWeek) {
    // If today is Friday and the queue is closed, set to Monday
    case 'friday':
      if (isQueueClosed) {
        nextWeekday = today.add(3, 'day')
      }
      break
    // If today is Saturday set to Monday
    case 'saturday':
      nextWeekday = today.add(2, 'day')
      break
    // If today is Sunday set to Monday
    case 'sunday':
      nextWeekday = today.add(1, 'day')
      break
    // If today is a weekday and queue closed set to next day
    default:
      if (isQueueClosed) {
        nextWeekday = today.add(1, 'day')
      }
  }

  // If today is a weekday and the queue is open, return today
  return nextWeekday
}

/**
 *
 * This function accepts a dayjs object representing the "next weekday",
 * the queue status, and a specific time of day, and returns the number
 * of hours between now, and the time we should show the next available
 * intake slot.
 */
export const getHoursUntilNextIntakeSlot = ({
  nextWeekday,
  queueStatus,
  timeOfDay,
  today,
}: {
  nextWeekday: dayjs.Dayjs
  queueStatus: CallMeNowQueueStatus
  timeOfDay: number
  today: dayjs.Dayjs
}) => {
  // Calculate the difference in hours from now until the next weekday at a specific time

  // If today is a weekday and the queue is open, return default start delay
  if (nextWeekday.isSame(today) && queueStatus !== 'closed') {
    return DEFAULT_START_DELAY_HOURS
  }

  // Set the hour of the next weekday
  const nextWeekDayAtSpecificTime = nextWeekday
    .set('hour', timeOfDay)
    .set('minute', 0)
    .tz(IANAZone.Eastern)

  // Need to pass true to get the diff as a float so we can round up below
  const hoursUntilNextWeekdayAtSpecificTime = nextWeekDayAtSpecificTime.diff(today, 'hours', true)

  // Round up to make sure we are showing the next available slots AFTER the specified time
  return Math.ceil(hoursUntilNextWeekdayAtSpecificTime)
}
