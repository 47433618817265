import { Skeleton, Stack } from '@shared/components'
import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { useFlags } from '../common/hooks'
import { routes } from '../common/routes'
import { PartnerPage } from '../welcome/pages/PartnerPage'
import * as Pages from './pages'

/*
 * We are migrating the old referral flow (ReferralsWorkflow) to the new referral flow (Referrals)
 * For the standard referral flow, this is controlled by the updatedReferralFlow flag
 * For partner-specific referral flows, we're still only using the old referral flow for now
 */
export const ReferralsApp = () => {
  const { updatedReferralFlow } = useFlags()

  /*
   * If the flag is not yet loaded, show a loading skeleton instead of redirecting to the wrong flow
   * This is important because <Pages.ReferralsWorkflow /> changes the url params
   */
  if (updatedReferralFlow === undefined) {
    return (
      <Stack spacing='sm'>
        <Skeleton height={15} />
        <Skeleton height={15} />
        <Skeleton height={15} />
        <Skeleton height={15} />
      </Stack>
    )
  }

  return (
    <Routes>
      <Route
        index
        element={updatedReferralFlow ? <Pages.Referrals /> : <Pages.ReferralsWorkflow />}
      />
      <Route
        path={routes.referrals.children.workflowPrompt}
        element={updatedReferralFlow ? <Pages.Referrals /> : <Pages.ReferralsWorkflow />}
      />
      <Route
        path=':referralPartnerId'
        element={
          <PartnerPage>
            <Pages.ReferralsWorkflow />
          </PartnerPage>
        }
      />
      <Route
        path={`:referralPartnerId${routes.referrals.children.workflowPrompt}`}
        element={
          <PartnerPage>
            <Pages.ReferralsWorkflow />
          </PartnerPage>
        }
      />
      <Route path='*' element={<Navigate replace to='/' />} />
    </Routes>
  )
}
