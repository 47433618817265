import { z } from 'zod'
import {
  CONSENT_TYPE_WITH_CONSENT_OBJECT,
  CONSENT_TYPE_WITH_NO_CONSENT_OBJECT,
} from '../../../consents'
import { ISOStringSchema, YYYYMMDDSchema } from '../../../dates'
import { ApiSchemas } from '../../utils'

const GetPatientCliniciansReqRes = {
  req: z.object({
    params: z.object({
      patientId: z.string(),
    }),
  }),
  res: z.object({
    data: z.object({
      pc: z
        .object({
          id: z.string(),
          name: z.string(),
          calendarId: z.number(),
        })
        .nullable(),
      ccm: z
        .object({
          id: z.string(),
          name: z.string(),
          calendarId: z.number(),
        })
        .nullable(),
    }),
  }),
}

const GetPatientServiceDatesReqRes = {
  req: z.object({
    params: z.object({
      patientId: z.string(),
    }),
  }),
  res: z.object({
    data: z.object({
      serviceDates: z.array(YYYYMMDDSchema),
    }),
  }),
}

const GetPatientPastInsuranceIdsReqRes = {
  req: z.object({
    params: z.object({
      patientId: z.string(),
    }),
  }),
  res: z.object({
    data: z.object({
      pastInsuranceIds: z.array(z.string()),
    }),
  }),
}

export const ConsentTypeWithConsentObjectSchema = z.enum(CONSENT_TYPE_WITH_CONSENT_OBJECT)
export const ConsentTypeWithNoConsentObjectSchema = z.enum(CONSENT_TYPE_WITH_NO_CONSENT_OBJECT)

export const PatientConsentWithConsentObjectSchema = z.object({
  type: ConsentTypeWithConsentObjectSchema,
  version: z.number(),
  signature: z.string(),
  consentDatetime: ISOStringSchema,
})

export const PatientConsentWithNoConsentObjectSchema = z.object({
  type: ConsentTypeWithNoConsentObjectSchema,
  consentDatetime: ISOStringSchema,
})

const CreatePatientConsentReqRes = {
  req: z.object({
    params: z.object({
      patientId: z.string(),
    }),
    body: z.discriminatedUnion('type', [
      PatientConsentWithConsentObjectSchema,
      PatientConsentWithNoConsentObjectSchema,
    ]),
  }),
  res: z.object({
    data: z.null(),
  }),
}

const GetPatientIntakeExpirationDatetimeReqRes = {
  req: z.object({
    params: z.object({
      patientId: z.string(),
    }),
  }),
  res: z.object({
    data: z.object({
      intakeTaskExpirationDatetime: ISOStringSchema.nullable(),
    }),
  }),
}

export const PatientRouteSchemas = {
  'GET /patients/:patientId/clinicians': GetPatientCliniciansReqRes,
  'GET /patients/:patientId/service-dates': GetPatientServiceDatesReqRes,
  'GET /patients/:patientId/past-insurances': GetPatientPastInsuranceIdsReqRes,
  'POST /patients/:patientId/consent': CreatePatientConsentReqRes,
  'GET /patients/:patientId/intake-tasks/expiration': GetPatientIntakeExpirationDatetimeReqRes,
} satisfies ApiSchemas
