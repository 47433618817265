import React, { FunctionComponent } from 'react'
import {
  Activity,
  AlertCircle,
  AlertTriangle,
  Archive,
  ArrowDown,
  ArrowDownRight,
  ArrowLeft,
  ArrowLeftCircle,
  ArrowRight,
  ArrowRightCircle,
  ArrowUp,
  ArrowUpRight,
  Award,
  Bell,
  BookOpen,
  Bookmark,
  Box,
  Calendar,
  Check,
  CheckCircle,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  ChevronsLeft,
  ChevronsRight,
  Circle,
  Clipboard,
  Clock,
  Coffee,
  Columns,
  Compass,
  Copy,
  CornerDownRight,
  CreditCard,
  Delete,
  DollarSign,
  Download,
  DownloadCloud,
  Droplet,
  Edit,
  Eye,
  EyeOff,
  Feather,
  File,
  FilePlus,
  Filter,
  Flag,
  Folder,
  Gift,
  Globe,
  Grid,
  Headphones,
  Heart,
  HelpCircle,
  Home,
  Inbox,
  Info,
  Layers,
  Layout,
  LifeBuoy,
  Link,
  List,
  Lock,
  LogIn,
  LogOut,
  Mail,
  Map,
  MapPin,
  MessageCircle,
  Mic,
  MicOff,
  Minus,
  MinusCircle,
  Monitor,
  Moon,
  MoreHorizontal,
  MoreVertical,
  Package,
  Paperclip,
  Edit2 as Pencil,
  Phone,
  PhoneCall,
  PhoneIncoming,
  PhoneMissed,
  PhoneOff,
  PhoneOutgoing,
  PlayCircle,
  Plus,
  PlusCircle,
  Printer,
  RefreshCw,
  Repeat,
  RotateCcw,
  RotateCw,
  Save,
  Search,
  Send,
  Settings,
  Share,
  Shield,
  ShoppingBag,
  Slash,
  Sliders,
  Smartphone,
  Smile,
  Star,
  StopCircle,
  Sun,
  Tag,
  ThumbsUp,
  Trash,
  Trash2,
  TrendingDown,
  TrendingUp,
  Truck,
  Unlock,
  User,
  UserCheck,
  UserPlus,
  UserX,
  Users,
  Video,
  VideoOff,
  Volume1,
  X,
  XCircle,
  Zap,
} from 'react-feather'
import { ConcentricCircles } from './customIcons/ConcentricCircles'
import { NotificationBell } from './customIcons/NotificationBell'
import { SolidCircle } from './customIcons/SolidCircle'
import { Sort } from './customIcons/Sort'
import { StyleProps, StyledCSS, useIconStyle } from './customIcons/style'

// Regular icons won't have a `styled` prop available
function withIconProps<P>(
  Component: FunctionComponent<P>,
  styling?: undefined,
): FunctionComponent<Omit<P, 'color'> & Omit<StyleProps, 'styled'>>

// Styling parameter must be provided for `styled` prop to be available
function withIconProps<P>(
  Component: FunctionComponent<P>,
  styling: StyledCSS,
): FunctionComponent<Omit<P, 'color'> & StyleProps>

function withIconProps<P>(Component: FunctionComponent<P>, styling: StyledCSS | undefined) {
  const IconWithProps = ({
    size = 'md',
    color = 'currentColor',
    xTransform = 0,
    yTransform = 0,
    styled = false,
    ...props
  }: Omit<P, 'color'> & StyleProps) => {
    const { classes } = useIconStyle({ styled, color, size, xTransform, yTransform, styling })
    return <Component className={classes.icon} {...(props as P)} />
  }

  IconWithProps.displayName = Component.displayName
  return IconWithProps
}

export const ActivityIcon = withIconProps(Activity)
export const AlertCircleIcon = withIconProps(AlertCircle)
export const AlertIcon = withIconProps(AlertTriangle)
export const ArchiveIcon = withIconProps(Archive)
export const ArrayDownIcon = withIconProps(ArrowDown)
export const ArrowDownRightIcon = withIconProps(ArrowDownRight)
export const ArrayUpIcon = withIconProps(ArrowUp)
export const ArrowLeftCircleIcon = withIconProps(ArrowLeftCircle, secondaryColor => ({
  '& circle': { stroke: secondaryColor },
}))
export const ArrowLeftIcon = withIconProps(ArrowLeft)
export const ArrowRightCircleIcon = withIconProps(ArrowRightCircle, secondaryColor => ({
  '& circle': { stroke: secondaryColor },
}))
export const ArrowRightIcon = withIconProps(ArrowRight)
export const ArrowUpRightIcon = withIconProps(ArrowUpRight)
export const AwardIcon = withIconProps(Award)
export const BellIcon = withIconProps(Bell)
export const BookmarkIcon = withIconProps(Bookmark)
export const BookIcon = withIconProps(BookOpen)
export const BoxIcon = withIconProps(Box)
export const CalendarIcon = withIconProps(Calendar)
export const CheckCircleIcon = withIconProps(CheckCircle)
export const CheckIcon = withIconProps(Check)
export const ChevronDownIcon = withIconProps(ChevronDown)
export const ChevronLeftIcon = withIconProps(ChevronLeft)
export const ChevronsLeftIcon = withIconProps(ChevronsLeft)
export const ChevronRightIcon = withIconProps(ChevronRight)
export const ChevronsRightIcon = withIconProps(ChevronsRight)
export const ChevronUpIcon = withIconProps(ChevronUp)
export const CircleIcon = withIconProps(Circle)
export const ClipboardIcon = withIconProps(Clipboard)
export const ClockIcon = withIconProps(Clock)
export const CoffeeIcon = withIconProps(Coffee)
export const CompassIcon = withIconProps(Compass)
export const ConcentricCirclesIcon = withIconProps(ConcentricCircles)
export const ColumnsIcon = withIconProps(Columns)
export const CopyIcon = withIconProps(Copy)
export const CornerDownRightIcon = withIconProps(CornerDownRight)
export const CreditCardIcon = withIconProps(CreditCard)
export const DeleteIcon = withIconProps(Delete)
export const DollarSignIcon = withIconProps(DollarSign)
export const DownloadIcon = withIconProps(Download)
export const DownloadCloudIcon = withIconProps(DownloadCloud)
export const DropletIcon = withIconProps(Droplet)
export const EditIcon = withIconProps(Edit, secondaryColor => ({
  '& path:first-of-type': { stroke: secondaryColor },
}))
export const EyeIcon = withIconProps(Eye)
export const EyeOffIcon = withIconProps(EyeOff)
export const FeatherIcon = withIconProps(Feather)
export const FileIcon = withIconProps(File)
export const FilePlusIcon = withIconProps(FilePlus)
export const FilterIcon = withIconProps(Filter)
export const FlagIcon = withIconProps(Flag)
export const FolderIcon = withIconProps(Folder)
export const GiftIcon = withIconProps(Gift)
export const GlobeIcon = withIconProps(Globe)
export const GridIcon = withIconProps(Grid)
export const HeadphonesIcon = withIconProps(Headphones)
export const HeartIcon = withIconProps(Heart)
export const HelpCircleIcon = withIconProps(HelpCircle, secondaryColor => ({
  '& circle': { stroke: secondaryColor },
}))
export const HomeIcon = withIconProps(Home)
export const InboxIcon = withIconProps(Inbox)
export const InfoIcon = withIconProps(Info)
export const LayersIcon = withIconProps(Layers)
export const LayoutIcon = withIconProps(Layout)
export const LifeBuoyIcon = withIconProps(LifeBuoy)
export const LinkIcon = withIconProps(Link)
export const ListIcon = withIconProps(List)
export const LockIcon = withIconProps(Lock)
export const LogInIcon = withIconProps(LogIn)
export const LogOutIcon = withIconProps(LogOut)
export const MailIcon = withIconProps(Mail)
export const MapIcon = withIconProps(Map)
export const MapPinIcon = withIconProps(MapPin)
export const MessageCircleIcon = withIconProps(MessageCircle)
export const MicIcon = withIconProps(Mic)
export const MicOffIcon = withIconProps(MicOff)
export const MinusCircleIcon = withIconProps(MinusCircle, secondaryColor => ({
  '& circle': { stroke: secondaryColor },
}))
export const MinusIcon = withIconProps(Minus)
export const MonitorIcon = withIconProps(Monitor)
export const MoonIcon = withIconProps(Moon)
export const MoreHorizontalIcon = withIconProps(MoreHorizontal)
export const MoreVerticalIcon = withIconProps(MoreVertical)
export const NotificationBellIcon = withIconProps(NotificationBell)
export const PackageIcon = withIconProps(Package)
export const PaperClipIcon = withIconProps(Paperclip)
export const PencilIcon = withIconProps(Pencil)
export const PhoneCallIcon = withIconProps(PhoneCall)
export const PhoneIcon = withIconProps(Phone)
export const PhoneIncomingIcon = withIconProps(PhoneIncoming)
export const PhoneMissedIcon = withIconProps(PhoneMissed)
export const PhoneOffIcon = withIconProps(PhoneOff)
export const PhoneOutgoingIcon = withIconProps(PhoneOutgoing)
export const PlayCircleIcon = withIconProps(PlayCircle)
export const PlusCircleIcon = withIconProps(PlusCircle, secondaryColor => ({
  '& circle': { stroke: secondaryColor },
}))
export const PlusIcon = withIconProps(Plus)
export const PrinterIcon = withIconProps(Printer)
export const RefreshIcon = withIconProps(RefreshCw)
export const RepeatIcon = withIconProps(Repeat)
export const RotateCwIcon = withIconProps(RotateCw)
export const RotateCwwIcon = withIconProps(RotateCcw)
export const SaveIcon = withIconProps(Save)
export const SearchIcon = withIconProps(Search)
export const SendIcon = withIconProps(Send)
export const SettingsIcon = withIconProps(Settings)
export const ShareIcon = withIconProps(Share)
export const ShieldIcon = withIconProps(Shield)
export const ShoppingBagIcon = withIconProps(ShoppingBag)
export const SlashIcon = withIconProps(Slash)
export const SlidersIcon = withIconProps(Sliders)
export const SmartphoneIcon = withIconProps(Smartphone)
export const SmileIcon = withIconProps(Smile)
export const SolidCircleIcon = withIconProps(SolidCircle)
export const SortIcon = withIconProps(Sort)
export const StarIcon = withIconProps(Star)
export const StopCircleIcon = withIconProps(StopCircle)
export const SunIcon = withIconProps(Sun)
export const TagIcon = withIconProps(Tag)
export const ThumbsUpIcon = withIconProps(ThumbsUp)
export const TrashCanIcon = withIconProps(Trash)
export const TrashIcon = withIconProps(Trash2)
export const TrendingDownIcon = withIconProps(TrendingDown)
export const TrendingUpIcon = withIconProps(TrendingUp)
export const TruckIcon = withIconProps(Truck)
export const UnlockIcon = withIconProps(Unlock)
export const UserCheckIcon = withIconProps(UserCheck)
export const UserIcon = withIconProps(User)
export const UserPlusIcon = withIconProps(UserPlus)
export const UserXIcon = withIconProps(UserX)
export const UsersIcon = withIconProps(Users)
export const VideoIcon = withIconProps(Video)
export const VideoOffIcon = withIconProps(VideoOff)
export const VolumeIcon = withIconProps(Volume1)
export const XCircleIcon = withIconProps(XCircle, secondaryColor => ({
  '& circle': { stroke: secondaryColor },
}))
export const XIcon = withIconProps(X)
export const ZapIcon = withIconProps(Zap)
