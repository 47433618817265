/* eslint-disable no-magic-numbers */
import { useForm } from '@mantine/form'
import {
  PrimaryButton,
  Stack,
  TextInput,
  TitleTwo,
  useLifecycle,
  validateWith,
} from '@shared/components'
import { PromptResponsePayload } from '@shared/types'
import { validCharacters } from '@shared/utils'
import React from 'react'
import { isAtleastOneWord, isRequired } from '../../../forms'
import { useAuth } from '../../../hooks'
import { sendPageEvent } from '../../../rudderstack'
import { getSessionStorageItem } from '../../../storage'
import { TimeZoneSelect } from '../../TimeZoneSelect'
import { WidgetProps } from '../PromptContentWidget'

export const EnrollmentCoordinatorSubmit = (props: WidgetProps) => {
  const { currentUser } = useAuth()

  const { getInputProps, validate, values } = useForm({
    initialValues: {
      firstName: '',
      lastName: '',
      timezone: '',
    },
    validate: {
      firstName: validateWith(isRequired, isAtleastOneWord),
      lastName: validateWith(isRequired, isAtleastOneWord),
      timezone: validateWith(isRequired),
    },
  })

  /*
   * If an EC is performing a welcome call and completing an onboarding flow
   * for someone, we'll use a special submit handler to get past the welcome call
   * scheduling screen
   */
  const ecSubmitDuringWelcomeCall = () => {
    if (validate().hasErrors) {
      return
    }

    const payload: PromptResponsePayload<'onboarding'> = {
      value: {
        contextKey: 'custom',
      },
      schedule_welcome_call_type: {
        contextKey: 'inbound_caller_call_me_now',
      },
      schedule_welcome_call_reconnect: {
        contextKey: 'custom',
        value: currentUser?.data?.statuses?.patient === 'discharged',
      },
      schedule_welcome_call_first_name: {
        contextKey: 'custom',
        value: values.firstName,
      },
      schedule_welcome_call_last_name: {
        contextKey: 'custom',
        value: values.lastName,
      },
      schedule_welcome_call_timezone: {
        contextKey: 'custom',
        value: values.timezone,
      },
      schedule_welcome_call_address_state: {
        contextKey: 'custom',
        value: getSessionStorageItem('residence_state') || currentUser?.data?.homeData?.state || '',
      },
    }

    props.onSubmit(payload)
  }

  useLifecycle({ onMount: () => sendPageEvent('EC Impersonation Welcome Flow Submit') })

  return (
    <Stack spacing='lg' test-id='content:schedule-welcome-call'>
      <TitleTwo>
        Enrollment Coordinator, please complete this last step on behalf of the patient
      </TitleTwo>
      <TextInput
        test-id='input:first-name'
        label={`Prospective patient's first name`}
        placeholder='First name'
        formatter={validCharacters.name}
        {...getInputProps('firstName')}
      />
      <TextInput
        test-id='input:last-name'
        label={`Prospective patient's last name`}
        placeholder='Last name'
        formatter={validCharacters.name}
        {...getInputProps('lastName')}
      />
      <TimeZoneSelect label={`Prospective patient's timezone`} {...getInputProps('timezone')} />

      <PrimaryButton fullWidth onClick={ecSubmitDuringWelcomeCall}>
        Complete on behalf of patient
      </PrimaryButton>
    </Stack>
  )
}
