import { DeepPartial } from './utils'

/*
 * Note: when we send attribute keys to our feature
 * flagging system, they need to be sufficiently vague
 * that no one could infer what Ophelia does from the
 * attribute name. For example, use `ct` instead of
 * `careTransfer`.
 *
 * For internal purposes, we can use more descriptive
 * attribute keys.
 */
export const vaguePatientAttributeKeys = ['ct', 'ca'] as const

export type VaguePatientAttributeKey = (typeof vaguePatientAttributeKeys)[number]

export const descriptivePatientAttributeKeys = ['is_care_transfer', 'is_chat_available'] as const

export type DescriptivePatientAttributeKey = (typeof descriptivePatientAttributeKeys)[number]

// Mapping of descriptive attribute names to vague keys
export const patientAttributeKeyMapping: {
  [key in DescriptivePatientAttributeKey]: VaguePatientAttributeKey
} = {
  is_care_transfer: 'ct',
  is_chat_available: 'ca',
}

export type PatientAttribute = {
  attributeName: DescriptivePatientAttributeKey
  attributeValue: string | boolean | number
}

export type ExperimentAttributes = DeepPartial<
  Record<DescriptivePatientAttributeKey, string | number | boolean>
>

/*
 * Patients will be sorted into test or control cohorts for each
 * experiment once they have the requisite attributes stored in
 * our feature flagging system. If the patient is not yet sorted
 * into a cohort, we will return 'not_sorted' or undefined
 */
export const experimentCohortOptions = ['test', 'control', 'not_sorted'] as const
export type ExperimentCohortOption = (typeof experimentCohortOptions)[number]

/*
 * To ensure that a patient is in an experiment cohort, we'll check
 * that the cohort is either `test` or `control`
 */
export const experimentCohorts = ['test', 'control', 'not_sorted'] as const

export type ExperimentCohort = (typeof experimentCohorts)[number]

/*
 * Experiments are a subset of feature flags. We will store a list
 * of experiments here so that we can fetch values for experiments
 * that patients are missing values for
 */
export const experimentNames = [
  'care-transfer-cohort-split',
  'reschedule-welcome-call-experiment',
  'reschedule-no-showed-welcome-call-experiment',
  'welcome-video-experiment',
  'welcome-chat-cohort-split',
  'welcome-flow-value-props',
  'intake-expiration-deadline',
] as const

export type ExperimentName = (typeof experimentNames)[number]

// Unauthenticated user experiments are a subset of ExperimentName
export const unauthenticatedUserExperimentNames = [
  'welcome-video-experiment',
  'welcome-flow-value-props',
] as const

export type UnauthenticatedUserExperimentName = (typeof unauthenticatedUserExperimentNames)[number]

// Welcome flow experiments are a subset of UnauthenticatedUserExperimentName
export const welcomeFlowExperimentNames = ['welcome-flow-value-props'] as const

export type WelcomeFlowExperimentName = (typeof welcomeFlowExperimentNames)[number]
