import { useMediaQuery } from '@mantine/hooks'
import {
  Alert,
  BellIcon,
  CalendarIcon,
  ChevronRightIcon,
  ClipboardIcon,
  ClockIcon,
  CreditCardIcon,
  DollarSignIcon,
  ExternalLinkText,
  FeatherIcon,
  GiftIcon,
  GlobeIcon,
  LifeBuoyIcon,
  MessageCircleIcon,
  SunIcon,
  TaskCard,
  Text,
  TruckIcon,
  TaskBar as TwilightTaskBar,
  UnstyledButton,
  UserIcon,
  VideoIcon,
  useMantineTheme,
} from '@shared/components'
import { PatientPortalTaskCard, TwilightIcon } from '@shared/types'
import { dayjs, getTimeDiffString, template } from '@shared/utils'
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useAuth, useMyQuery, usePortalDims } from '../../hooks'
import { sendTrackEvent } from '../../rudderstack'

type TaskBarProps = {
  tasks: PatientPortalTaskCard[]
}

export const TaskBar = ({ tasks }: TaskBarProps) => {
  const theme = useMantineTheme()
  const { isMobile } = usePortalDims()
  const { currentUser } = useAuth()
  // Have to use this rather than isMobile - different sized breakpoints (xs vs md)
  const isSmallDevice = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`)
  const shouldExpandCardWidth = isSmallDevice && tasks.length === 1

  useEffect(() => {
    if (tasks.find(task => task.type === 'intake-visit-standby-list')) {
      sendTrackEvent('Standby List Task Seen', { type: 'intake-visit-standby-list' })
    }
  }, [tasks])

  const isInTestCohortForIntakeExpirationExperiment =
    currentUser?.data?.experiments?.['intake-expiration-deadline']?.cohort === 'test'

  const { data: intakeExpirationDatetimeQuery } = useMyQuery('GET /intake-tasks/expiration', {
    enabled: isInTestCohortForIntakeExpirationExperiment,
  })

  const intakeTaskExpirationDatetime =
    intakeExpirationDatetimeQuery?.data?.intakeTaskExpirationDatetime

  const intakeTaskExpirationAlertTitleText = template(
    'Required tasks are due in {expirationDateString}',
    {
      expirationDateString: getTimeDiffString(intakeTaskExpirationDatetime || ''),
    },
  )

  const intakeTaskExpirationAlertBodyText = template(
    'Your intake visit will be canceled if tasks are not completed by {intakeExpirationTime} on {intakeExpirationDate}',
    {
      intakeExpirationTime: dayjs(intakeTaskExpirationDatetime).format('h:mma z'),
      intakeExpirationDate: dayjs(intakeTaskExpirationDatetime).format('MMM D, YYYY').toUpperCase(),
    },
  )

  if (tasks.length === 0) {
    return null
  }

  const hasRequiredTasks = tasks.some(task => task.requiredFor.length)

  return (
    <TwilightTaskBar
      sx={{ display: 'grid' }}
      ml={isMobile ? 0 : theme.spacing.xs}
      header='Tasks'
      /*
       * @intakeTaskExpirationDeadlineExperiment
       * Only display the intake expiration alert if the patient is in the test cohort
       * for the experiment, there is a valid intake expiration datetime, and there are
       * required tasks remaining
       */
      subheader={
        hasRequiredTasks &&
        intakeTaskExpirationDatetime &&
        isInTestCohortForIntakeExpirationExperiment && (
          <Alert
            variant='primary'
            title={
              <Text color={colors => colors.text[2]} bold>
                {intakeTaskExpirationAlertTitleText}
              </Text>
            }
            icon={<ClockIcon />}
          >
            <Text size='xs' color={colors => colors.text[2]}>
              {intakeTaskExpirationAlertBodyText}
            </Text>
          </Alert>
        )
      }
      number={tasks.length}
      footer={
        <Text>
          {'Text '}
          <ExternalLinkText href='sms:2155852144'>(215) 585-2144</ExternalLinkText>
          {' for support'}
        </Text>
      }
    >
      {tasks.map(task => {
        return (
          <UnstyledButton
            component={Link}
            test-id={`task-card:${task.type}`}
            to={task.path}
            key={task.type}
            sx={{
              display: 'flex',
              flexGrow: shouldExpandCardWidth ? 1 : 0,
              ':focus': { outline: 'none', '& div': { textDecoration: 'underline' } },
              ...(task.disabled ? { pointerEvents: 'none' } : {}),
            }}
          >
            <TaskCard
              title={task.title}
              icon={!task.disabled && getIconFromStringName(task.icon)}
              /*
               * @intakeTaskExpirationDeadlineExperiment
               * Only show the task due dates in the footer if the patient is not in the test cohort
               * for the experiment. If the patient is in the test cohort then there will be a banner
               * above the task bar indicating the due date for all required tasks.
               */
              footer={isInTestCohortForIntakeExpirationExperiment ? undefined : task.footer}
              fillWidth={shouldExpandCardWidth}
              requiredMessage={task.requiredMessage}
              disabled={task.disabled}
            />
          </UnstyledButton>
        )
      })}
    </TwilightTaskBar>
  )
}

const getIconFromStringName = (icon: TwilightIcon) => {
  // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
  switch (icon) {
    case 'Calendar':
      return <CalendarIcon />
    case 'User':
      return <UserIcon />
    case 'ChevronRight':
      return <ChevronRightIcon />
    case 'Clipboard':
      return <ClipboardIcon />
    case 'Globe':
      return <GlobeIcon />
    case 'LifeBuoy':
      return <LifeBuoyIcon />
    case 'MessageCircle':
      return <MessageCircleIcon />
    case 'DollarSign':
      return <DollarSignIcon />
    case 'CreditCard':
      return <CreditCardIcon />
    case 'Truck':
      return <TruckIcon />
    case 'Bell':
      return <BellIcon />
    case 'Sun':
      return <SunIcon />
    case 'Feather':
      return <FeatherIcon />
    case 'Gift':
      return <GiftIcon />
    case 'Video':
      return <VideoIcon />
  }
}
