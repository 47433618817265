import { UseFormReturnType } from '@mantine/form'
import { Radio, RadioGroup, Select, Stack, Text, TitleTwo } from '@shared/components'
import { StateName, activeStates, states } from '@shared/types'
import {
  getPayersShortList,
  hasSelectedInsuranceFromShortlist,
  hasSelectedInsuranceNotOnShortList,
} from '@shared/utils'
import React, { useEffect, useMemo } from 'react'
import { InsuranceSelect } from '../../../common/components'
import { sendTrackEvent } from '../../../common/rudderstack'
import { ReferralFormForm } from '../Referrals'

type InsuranceSectionProps = {
  form: UseFormReturnType<ReferralFormForm>
}

export const InsuranceSection = ({ form }: InsuranceSectionProps) => {
  const insurances = useMemo(
    () => getPayersShortList(form.values.referralState),
    [form.values.referralState],
  )
  const insuranceOtherOptionValue = 'other_none_of_these__Other/none of these'
  const sortedOptions = insurances.sort((a, b) => {
    if (a.value === insuranceOtherOptionValue) {
      return -1
    } else if (b.value === insuranceOtherOptionValue) {
      return 1
    }
    return 0
  })

  const validStates = activeStates.map(state => state.state)

  const isSelectedStateInvalid =
    (form.values.referralState as StateName) &&
    !validStates.includes(form.values.referralState as StateName)

  useEffect(() => {
    if (form.values.intendedPaymentMethod !== 'Insurance') {
      form.setFieldValue('insuranceProvider', '')
    }
  }, [form.values.intendedPaymentMethod])

  useEffect(() => {
    form.setFieldValue('insuranceProvider', '')

    if (isSelectedStateInvalid) {
      // Track when a user selects a state that we don't serve
      sendTrackEvent('Referral Flow State Selected', { state: form.values.referralState })
    }
  }, [form.values.referralState])

  return (
    <Stack>
      <TitleTwo>{`First, let's check insurance`}</TitleTwo>
      <Text>
        We offer the same evidence-based treatment to all patients, regardless of how they choose to
        pay.
      </Text>
      <Select
        searchable
        placeholder='Select one...'
        label="Referral's state of residence"
        data={[...states.map(state => state.state), 'Other']}
        warning={isSelectedStateInvalid ? `Unfortunately, we're not active in this state yet` : ''}
        {...form.getInputProps('referralState')}
      />
      <Text>Does your referral have insurance?</Text>
      <Stack spacing='sm' test-id='content:payment-method'>
        <RadioGroup {...form.getInputProps('intendedPaymentMethod')}>
          <Radio value='Insurance' label='Yes, referral will pay with insurance' />
          <Radio value='Cash' label='No, referral will not pay with insurance' />
          <Radio value='Unknown' label='Not sure if referral will have insurance coverage' />
        </RadioGroup>
        {form.values.intendedPaymentMethod === 'Insurance' && (
          <InsuranceSelect
            success={
              hasSelectedInsuranceFromShortlist(form.values.insuranceProvider) &&
              `We accept their insurance`
            }
            warning={
              hasSelectedInsuranceNotOnShortList(form.values.insuranceProvider) &&
              `We only accept insurances listed above`
            }
            data={sortedOptions}
            {...form.getInputProps('insuranceProvider')}
            placeholder='Type to search for a plan...'
            searchable
            rightSection={<> </>}
            clearable
          />
        )}
      </Stack>
    </Stack>
  )
}
