import { UseFormReturnType } from '@mantine/form'
import {
  Alert,
  DateInput,
  Grid,
  InfoIcon,
  PhoneInput,
  Stack,
  TextInput,
  TitleTwo,
} from '@shared/components'
import { validCharacters } from '@shared/utils'
import React from 'react'
import { SexSelect } from '../../../common/components/SexSelect'
import { ReferralFormForm } from '../Referrals'

type PersonalInformationSectionProps = {
  form: UseFormReturnType<ReferralFormForm>
}

export const PersonalInformationSection = ({ form }: PersonalInformationSectionProps) => {
  const isSaving = false

  return (
    <Stack>
      <TitleTwo>{`Next, share your referral's information`}</TitleTwo>
      <Grid>
        <Grid.Col span={12} md={6}>
          <TextInput
            label={`Referral's first name`}
            placeholder={`Referral's first name`}
            maxLength={64}
            disabled={isSaving}
            formatter={validCharacters.name}
            {...form.getInputProps('referralFirstName')}
          />
        </Grid.Col>
        <Grid.Col span={12} md={6}>
          <TextInput
            label={`Referral's last name`}
            placeholder={`Referral's last name`}
            maxLength={64}
            disabled={isSaving}
            formatter={validCharacters.name}
            {...form.getInputProps('referralLastName')}
          />
        </Grid.Col>
        <Grid.Col span={12} md={6}>
          <SexSelect
            label={`Referral's sex assigned at birth`}
            disabled={isSaving}
            {...form.getInputProps('referralSex')}
          />
        </Grid.Col>
        <Grid.Col span={12} md={6}>
          <DateInput
            label={`Referral's date of birth`}
            disabled={isSaving}
            {...form.getInputProps('referralDob')}
          />
        </Grid.Col>
        <Grid.Col span={12} md={12}>
          <Alert
            icon={<InfoIcon />}
            variant='primary'
            title={`We'll need a way to contact your referral by phone call and email`}
          >{`It's okay if the phone number or email address doesn't belong to them.`}</Alert>
        </Grid.Col>
        <Grid.Col span={12} md={6}>
          <PhoneInput
            label="Referral's phone number"
            disabled={isSaving}
            {...form.getInputProps('referralPhone')}
          />
        </Grid.Col>
        <Grid.Col span={12} md={6}>
          <TextInput
            label={`Referral's email address`}
            disabled={isSaving}
            placeholder={`Referral's email address`}
            {...form.getInputProps('referralEmail')}
          />
        </Grid.Col>
      </Grid>
    </Stack>
  )
}
