import { z } from 'zod'
import { YYYYMMDDSchema } from '../../../dates'
import { ENCOUNTER_APPOINTMENT_TYPES } from '../../../encounters'
import { OrderByDirectionEnum } from '../../../zodUtils'
import { ApiSchemas } from '../../utils'

const InsuranceCard = z.object({
  payer_name: z.string(),
})

const EncounterSubscriber = z.object({
  insurance_card: InsuranceCard,
})

const ZodIssue = z.object({
  fatal: z.boolean().optional(),
  message: z.string().optional(),
})

const EncounterPatient = z.object({
  last_name: z.string(),
  first_name: z.string(),
  address: z.object({
    state: z.string(),
  }),
})

export const encounterStatuses = [
  'unsubmitted',
  'not_billable',
  'submitted',
  'allow_list_false',
  'flagged',
  'auto_submitting',
] as const

const GetEncountersCountReqRes = {
  req: z.object({
    query: z.object({
      status: z.enum(encounterStatuses),
      payerId: z.string().optional(),
      payerEnrollmentId: z.string().optional(),
    }),
  }),
  res: z.object({
    data: z.object({
      count: z.number(),
    }),
  }),
}

const GetEncountersReqRes = {
  req: z.object({
    query: z.object({
      status: z.enum(encounterStatuses),
      limit: z.string().optional(),
      startAfter: z.string().optional(),
      order: OrderByDirectionEnum.optional(),
      patientId: z.string().optional(),
      payerId: z.string().optional(),
      payerEnrollmentId: z.string().optional(),
    }),
  }),
  res: z.object({
    data: z.object({
      encounters: z.array(
        z.object({
          appointment_type: z.enum(ENCOUNTER_APPOINTMENT_TYPES),
          oid: z.string(),
          date_of_service: YYYYMMDDSchema,
          subscriber_primary: EncounterSubscriber,
          patient: EncounterPatient,
          errors: z.array(ZodIssue).optional(),
        }),
      ),
    }),
  }),
}

const PostCloneEncounterReqRes = {
  req: z.object({
    params: z.object({
      encounterId: z.string(),
    }),
  }),
  res: z.object({
    data: z.object({
      patientId: z.string(),
    }),
  }),
}

export const EncountersRouteSchemas = {
  'GET /encounters': GetEncountersReqRes,
  'GET /encounters/count': GetEncountersCountReqRes,
  'POST /encounters/:encounterId/clone': PostCloneEncounterReqRes,
} satisfies ApiSchemas
