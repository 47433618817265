import { AppointmentTypeString, PasswordType } from '..'
import { AcuityTimeSlot } from '../acuity'

export type Availability = {
  time: TimeOfDay
  weekday: DayOfWeek
}

export const TimeOfDayToHourRanges: Record<TimeOfDay, number[]> = {
  // eslint-disable-next-line no-magic-numbers
  morning: [8, 9, 10, 11],
  // eslint-disable-next-line no-magic-numbers
  afternoon: [12, 13, 14, 15, 16, 17],
  // eslint-disable-next-line no-magic-numbers
  evening: [18, 19, 20, 21],
}

export type TimeOfDay = 'morning' | 'afternoon' | 'evening'

export const DAYS_READONLY = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
] as const
export type DayOfWeek = (typeof DAYS_READONLY)[number]
export type Day = (typeof DAYS_READONLY)[number]

export const BUSINESS_DAYS: Day[] = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday']
export const DAYS: Day[] = [...DAYS_READONLY]

export type StandbyListNotificationSettings = {
  /**
   * optIn: indicates that a patient has confirmed they want
   * standbyList notifications
   */
  optIn?: boolean
  /**
   * availabilities: if null, the user has no time
   * preferences, so any new slot will trigger a notification
   */
  availabilities: Availability[] | null
  /**
   * previousAvailableSlots: an array of ISO timestamps
   */
  previousAvailableSlots?: AcuityTimeSlot[]
}

export const STANDBY_APPOINTMENT_FIELD: Partial<
  Record<AppointmentTypeString, keyof AccountSettings['notifications']['standbyList']>
> = {
  'Initial Visit': 'initialVisit',
  'Free Consultation Call': 'welcomeCall',
}

export type AccountSettings = {
  notifications: {
    standbyList: {
      initialVisit?: StandbyListNotificationSettings
      welcomeCall?: StandbyListNotificationSettings
    }
  }
  availabilities: Record<TimeOfDay, Day[]>
}

export const PATIENT_SOURCE = [
  'Fidelis',
  'Bucks County Correctional Facility',
  'Livengrin',
  'Elk County Prison',
  'Saratoga County Prison',
  'Warren County Prison',
  'Allegheny Health Network',
  'SCI Albion',
  'SCI Dallas',
  'SCI Fayette',
  'SCI Frackville',
  'SCI Quehanna Bootcamp',
  'Greene County Prison',
  'Lancaster County Prison',
  'McKean County Jail',
  'Potter County Jail',
  'Albany County Correctional Facility',
  'Atlantic County Correctional Facility',
  'Just For Today - Lemoyne',
  'Other (allows patient to bypass tasks)',
] as const
export type PatientSource = (typeof PATIENT_SOURCE)[number]

export const offlineSources: PatientSource[] = [
  'Bucks County Correctional Facility',
  'Livengrin',
  'Elk County Prison',
  'Saratoga County Prison',
  'Warren County Prison',
  'Allegheny Health Network',
  'SCI Albion',
  'SCI Dallas',
  'SCI Fayette',
  'SCI Frackville',
  'SCI Quehanna Bootcamp',
  'Greene County Prison',
  'Lancaster County Prison',
  'McKean County Jail',
  'Potter County Jail',
  'Albany County Correctional Facility',
  'Atlantic County Correctional Facility',
  'Just For Today - Lemoyne',
  'Other (allows patient to bypass tasks)',
]

export type AccountData = {
  createdAt: string
  emailConfirmed?: boolean
  emailConfirmedAt?: string
  password?: string | null
  passwordType?: PasswordType
  phoneConfirmed?: boolean
  settings?: Partial<AccountSettings>
  timezone?: string
  isZoomWorking?: boolean
  zoomLastTested?: string
  source?: PatientSource
  // @deprecated Nov 2024
  careTransfer?:
    | {
        // is someone actively taking suboxone prescribed by a medical professiona?
        isCareTransfer: boolean
        // test cohort gets expedited care transfer flow & control gets standard onboarding flow
        careTransferExperimentGroup?: 'test_cohort' | 'control_cohort'
      }
    | undefined
}

export enum OptIn {
  Yes = 'yes',
  No = 'no',
}
