import { UseFormReturnType } from '@mantine/form'
import {
  Alert,
  AlertCircleIcon,
  Checkbox,
  DailyCalendar,
  DailyCalendarRadioGroup,
  Radio,
  Stack,
  Text,
  TitleTwo,
  useBanner,
} from '@shared/components'
import { activeStates, StateName } from '@shared/types'
import { dayjs, toTime } from '@shared/utils'
import last from 'lodash/last'
import React, { useEffect, useState } from 'react'
import { Skeletons } from '../../../common/components'
import { CalendarMessage } from '../../../common/components/CalendarMessage'
import { useMyQuery } from '../../../common/hooks'
import { ReferralFormForm } from '../Referrals'

type IntakeVisitScheduleSectionProps = {
  form: UseFormReturnType<ReferralFormForm>
}

const START_DATE_DAYS_AFTER = 1
const RANGE_DURATION = 90

export const IntakeVisitScheduleSection = ({ form }: IntakeVisitScheduleSectionProps) => {
  const startDate = dayjs().add(START_DATE_DAYS_AFTER, 'days')
  const endDate = startDate ? startDate.add(RANGE_DURATION, 'days') : null
  const [date, setDate] = useState(startDate)
  const [range, setRange] = useState<{ date: string; available: boolean }[]>([])
  const { showBanner, hideBanner } = useBanner()

  const calendarsQuery = useMyQuery(
    'GET /public/calendars',
    {
      query: {
        state: form.values.referralState,
      },
    },
    {
      enabled: Boolean(
        form.values.referralState &&
          activeStates.map(state => state.state).includes(form.values.referralState as StateName),
      ),
    },
  )

  const calendarIdsData = calendarsQuery?.data?.data?.calendarIds || []

  const { isLoading: isRangeLoading, data: rangeData } = useMyQuery(
    'GET /public/appointments/calendar/intake',
    {
      query: {
        calendarIds: calendarIdsData,
        state: form.values.referralState ?? '',
        start: date?.toISOString(),
        duration: RANGE_DURATION.toString(),
      },
    },
    {
      cacheTime: toTime('10 sec').ms(),
      enabled: calendarIdsData.length > 0,
      onSuccess: data => {
        const availableRange = data?.data
        if (startDate && endDate) {
          let processedRange: { date: string; available: boolean }[]
          if (!availableRange || availableRange.length === 0) {
            processedRange = startDate
              .getBetween(endDate, 'days')
              .map(date => ({ date, available: false }))
          } else {
            const range = startDate.getBetween(endDate, 'days')
            processedRange = range.map((date: string) => ({
              date,
              available: availableRange.some(slot => dayjs(slot.date).isSame(date)),
            }))
          }

          setRange(processedRange)

          // Find the first available date or default to the first date in the range
          const firstAvailableSlot = processedRange.find(slot => slot?.available)
          const initialDate = firstAvailableSlot?.date || processedRange[0]?.date

          if (initialDate) {
            setDate(dayjs(initialDate))
          }
        } else {
          setRange([])
          setDate(dayjs(null))
        }
      },
      onError: () => {
        showBanner({ type: 'error', label: 'Something went wrong, try again later' })
      },
    },
  )

  const noAvailabilityInState =
    !rangeData?.data?.length && !isRangeLoading && !calendarsQuery.isLoading

  const isLastDay = dayjs(last(range)?.date).isSame(date, 'day')
  const isAvailable = Boolean(
    range?.find((slot: { date: string; available: boolean }) => date?.isSame(slot.date, 'day'))
      ?.available,
  )

  const { data: intakeTimes, isLoading: isIntakeTimesLoading } = useMyQuery(
    'GET /public/appointments/calendar/intake/:date',
    {
      params: { date: date?.format('YYYY-MM-DD') ?? '' },
      query: {
        start: date?.isValid() ? dayjs(date)?.toISOString() : '',
        calendarIds: calendarIdsData,
        state: form.values.referralState ?? '',
      },
    },
    {
      cacheTime: toTime('10 sec').ms(),
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      // Don't fetch for last date, we show a message of booking too far
      enabled: calendarIdsData.length > 0 && isAvailable && !isLastDay,
      onError: () => {
        showBanner({ type: 'error', label: 'Something went wrong, try again later' })
      },
    },
  )

  const timeslots = intakeTimes?.data

  useEffect(() => {
    if (noAvailabilityInState) {
      form.setFieldValue('skipIntakeVisitSchedule', true)
    } else {
      form.setFieldValue('skipIntakeVisitSchedule', false)
    }
  }, [noAvailabilityInState])

  if (isRangeLoading || calendarsQuery.isLoading) {
    return <Skeletons />
  }

  return (
    <Stack spacing='md'>
      <TitleTwo>Schedule an intake visit for your referral (optional)</TitleTwo>
      <Text>
        If you don’t know when your referral is available, you can skip this step. We will follow up
        with your referral to schedule their intake.
      </Text>
      {form.values.referralState && noAvailabilityInState && (
        <Alert icon={<AlertCircleIcon />} variant='secondary'>
          No availability in selected state
        </Alert>
      )}
      {form.values.referralState && !noAvailabilityInState && (
        <>
          <DailyCalendar
            nextDayPulse={timeslots?.length === 0}
            previousDayPulse={isLastDay}
            value={date?.toString() ?? ''}
            range={range?.map(slot => slot.date) ?? []}
            onChange={value => {
              form.clearErrors()
              form.setValues({ intakeDatetime: '' })
              setDate(dayjs(value))
              hideBanner()
            }}
          >
            {isLastDay ? (
              <CalendarMessage>
                {`Sorry, we don't have availability right now. Please email partnerships@ophelia.com or
                check back again later.`}
              </CalendarMessage>
            ) : (
              <DailyCalendarRadioGroup
                size={6}
                loading={isIntakeTimesLoading}
                empty={<CalendarMessage>Sorry, we&apos;re booked on this day.</CalendarMessage>}
                {...form.getInputProps('intakeDatetime')}
              >
                {timeslots?.map(timeslot => {
                  return (
                    <Radio
                      disabled={form.values.skipIntakeVisitSchedule}
                      key={timeslot.time}
                      value={timeslot.time}
                      label={dayjs(timeslot.time).format('h:mma z')}
                    />
                  )
                })}
              </DailyCalendarRadioGroup>
            )}
          </DailyCalendar>
          <Checkbox
            label='Skip intake visit scheduling'
            {...form.getInputProps('skipIntakeVisitSchedule', { type: 'checkbox' })}
            onClick={() => {
              const newValue = !form.values.skipIntakeVisitSchedule
              form.setFieldValue('skipIntakeVisitSchedule', newValue)
              if (newValue) {
                form.setFieldValue('intakeDatetime', '')
              }
            }}
          />
        </>
      )}
      {!form.values.referralState && (
        <Alert icon={<AlertCircleIcon />} variant='secondary'>
          Add referral’s state of residence to load scheduler
        </Alert>
      )}
    </Stack>
  )
}
