import * as FullStory from '@fullstory/browser'
import { Config } from './config'
import { getSessionData } from './userSession'

export const { setUserVars, getCurrentSessionURL, identify, log } = FullStory

export type LogLevel = 'log' | 'info' | 'warn' | 'error' | 'debug'

export const init = async () => {
  FullStory.init({
    orgId: Config.FULLSTORY_ORG_ID,
    namespace: 'Patient',
    devMode: Config.NODE_ENV !== 'production',
  })

  const sessionData = await getSessionData()

  setUserVars({
    ...sessionData,
    environment: Config.NODE_ENV,
  })
}

type FullStoryEventName =
  | 'Account Recovery Request Visible'
  | 'Account Recovery Update Attempted'
  | 'Call me now CTA is visible'
  | 'Call us now visible'
  | 'Call us now CTA pressed'
  | 'Click Check My Insurance On Welcome Page'
  | 'Clicked From ConsultationCallCalendar To CallMeNowPage'
  | 'Cost Analysis Page Visited'
  | 'Cost Analysis Page Progress'
  | 'Insurance Select Step Visible'
  | 'Set Email Visible'
  | 'Confirmation Email Sent Page Visible'
  | 'Coverage Search Options Step Visible'
  | 'Coverage Results Step Visible'
  | 'Referral Link Clicked'
  | 'Referral Link Clicked & Existing Referral Found'
  | 'Existing Referral Found'
  | 'Schedule My Free Intro Call Clicked'
  | 'Standby List Task Seen'
  | 'Standby List Task Opened'
  | 'No Reschedule Availability'
  | 'No Schedule Availability'
  | 'Zoom Redirect Page Error'
  | 'Zoom Redirect Page Visited'
  | 'MBC Before Zoom'
  | 'Financial Consent Before Zoom'
  | 'Financial Consent Signed'
  | 'Patient ErrorBoundary Rendered'
  | 'Patient would like to speak with a financial counselor'
  | '[Experiment] Payment plan signup section seen'
  | 'Partner Welcome Page Visited'
  | 'Combined Wellness Assessment Start Page Visited'
  | 'Partner Referral Onboarding'
  | 'Welcome Call Scheduled For Partner Referral'
  | 'Clicked Stripe Payment Request Button Element'
  | 'Rendered Stripe Payment Request Button Element'
  | 'Partner Welcome Page - Have A Question Visible'
  | 'Partner Welcome Page - Have A Question Clicked'
  | 'Payment Succeeded Using Stripe Payment Request Button Element'
  | 'Friend Referral Link Copied'
  | 'Learn More Clicked'
  | 'Patient Portal Zoom Embed Initialization Failed'
  | 'Patient Portal Zoom Embed Initialization Succeeded'
  | 'Patient Portal Zoom Embed Join Failed'
  | 'Patient Portal Zoom Embed Join Succeeded'
  | 'Patient Portal Zoom Embed Fallback Selected'
  | 'Milestone Shared'
  | 'Not Ready Clicked'
  | 'Rendered Standby Link Expired Message'
  | 'CMN copy experiment cohort determined'
  | 'Skip Onboarding Drawer Open'
  | 'Onboarding Skipped To Book Instant Welcome Call'
  | 'Onboarding Workflow Prompt Visible'
  | 'Insurance Coverage Page Visited'
  | 'Zero Dollar Alert Visible'
  | 'Test Zoom Clicked'
  | 'Scheduled Follow-Up Visit For Hold'
  | 'Error Scheduling Follow-Up Visit For Hold'
  | 'Error Getting Availability For Follow-Up Visit Hold'
  | 'No Availability For Follow-Up Visit Hold'
  | 'Clicked Support Because No Visit Hold Time Works'
  | 'Visit FAQs Clicked'
  | 'Motivations for Treatment Example Drawer Opened'
  | 'Loaded Availability For Follow-Up Visit Hold'
  | 'Navigating back to phone step as current step is invalid'
  | 'Navigating back to phone step as no phone number in form state'
  | 'TPO Consent Before Zoom'
  | 'Signed Out From Workflow Prompt'
  | 'Prompt Signout Modal Visible'
  | 'Account Recovery Options Visible'
  | 'Account Recovery Contact Visible'
  | 'Care Transfer Intake Scheduled For Partner Referral'
  | 'Patient Is In Care Transfer Test Cohort'
  | 'Patient Is In Care Transfer Segment'
  | 'Start Welcome Chat CTA is visible'
  | 'Correctional Facility Intake Scheduled in Referral Flow'
  | 'API Error'
  // @welcomeCallReschedulingExperiment
  | '[Welcome Call Experiment] No Availability'
  | '[Welcome Call Experiment] No Appointment Found'
  | '[Welcome Call Experiment] Clicked Preemptive Link But Is No Show'
  | '[Welcome Call Experiment] Clicked No Show Link But Is Preemptive'
  // The following event will include the campaign the lead was referred from
  | '[Welcome Call Experiment] Viewed Page'
  | '[Welcome Call Experiment] Selected Appointment Time'
  | '[Welcome Call Experiment] Rescheduled Appointment'
  // The following event will include the numDaysFromToday
  | '[Welcome Call Experiment] Toggled Calendar Date'
  | '[Welcome Call Experiment] Clicked CMN Banner'
  | '[Welcome Call Experiment] Selected CMN Option'
  | '[Welcome Call Experiment] Not Able To Reschedule Welcome Call'
  | '[Welcome Chat] Chatbox Closed'
  | '[Welcome Chat] Chatbox Visible'
  | 'Welcome Page View'
  | '[Welcome Video] Page View'
  | '[Welcome Video] Video Clicked'
  | '[Welcome Video] Video Started'
  | '[Welcome Video] Video Loading'
  | '[Welcome Video] Video Ended'
  | 'Welcome Flow Experiment Cohorted'
  | '[Welcome Chat] Conversation Started'

export const event = (
  eventName: FullStoryEventName,
  eventProperties: { [key: string]: string | number } = {},
) => {
  FullStory.event(eventName, eventProperties)
}

export const page = (props?: { [key: string]: string | number }) => {
  FullStory.setVars('page', props)
}
